import { forwardRef, useState } from 'react'
import ru from 'date-fns/locale/ru'
import dateFnsFormat from 'date-fns/format'
import DatePicker from 'react-datepicker'
import { ReactComponent as DropdownIcon } from 'images/icons/arrowSelect.svg'
import 'react-datepicker/dist/react-datepicker.css'
import './CustomDatePicker.css'

const CustomInput = forwardRef<
  HTMLDivElement,
  { value: string | null | undefined; onClick: () => void; isOpen: boolean; placeholder: string }
>(({ value, onClick, isOpen, placeholder }, ref) => (
  <div className="example-custom-input" ref={ref} onClick={onClick}>
    {value ? value : placeholder}
    {<DropdownIcon className={isOpen ? 'iconDropdownOpen' : 'iconDropdown'} />}
  </div>
))

const CustomDatePicker = ({
  date,
  onChange,
  placeholderText,
  disabled = false,
}: {
  date: Date | null | undefined
  onChange: (date: Date | null) => void
  placeholderText: string
  disabled: boolean
}) => {
  const [open, setOpen] = useState(false)
  const onClick = () => {
    if (disabled) return
    setOpen(!open)
  }
  const onClickOutside = (e: React.MouseEvent) => {
    setOpen(false)
  }

  const handleDateChange = (date: Date | null) => {
    setOpen(false)
    onChange(date)
  }
  return (
    <DatePicker
      selected={date}
      onChange={handleDateChange}
      dateFormat={'dd.MM.YYYY'}
      locale={ru}
      showYearDropdown={true}
      isClearable={true}
      todayButton="Сегодня"
      open={open}
      disabled={disabled}
      onInputClick={onClick}
      onClickOutside={onClickOutside}
      placeholderText={placeholderText}
      showPopperArrow={false}
      customInput={
        <CustomInput
          value={date && dateFnsFormat(date, 'mm.dd.yyyy')}
          onClick={onClick}
          isOpen={open}
          placeholder={placeholderText}
        />
      }
    />
  )
}

export default CustomDatePicker
