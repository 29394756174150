import { VideoImportReadFromRemoteStorage } from 'api/schema'
import BaseModal from 'components/BaseModal/BaseModal'
import style from './EditVideoModal.module.css'
import Dropdown from 'components/Dropdown/Dropdown'
import { useSelector } from 'react-redux'
import prettyBytes from 'pretty-bytes'
import * as yup from 'yup'
import { RootState } from 'utils/interfaces'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { urlSchema } from 'utils/validationSchema'
import { Button } from 'components/Button/Button'
import { useCallback, useMemo } from 'react'
import dateFnsFormat from 'date-fns/format'
import ru from 'date-fns/locale/ru'
import clsx from 'clsx'
import TextFieldStyled from 'components/TextFieldStyled/TextFieldStyled'
import { generatePath, useNavigate } from 'react-router-dom'
import { PATH } from 'utils/constants'

interface IFormInputs {
  linkMp4: string
  linkSrt: string
}

const EditVideoModal = ({
  videoImport: video,
  onClose,
}: {
  videoImport?: VideoImportReadFromRemoteStorage
  onClose: () => void
}) => {
  const navigate = useNavigate()
  const methods = useForm<IFormInputs>({
    resolver: yupResolver(yup.object().shape({ linkMp4: urlSchema(), linkSrt: urlSchema() })),
    defaultValues: {
      linkMp4: video?.url_video || '',
      linkSrt: video?.url_subs || '',
    },
  })
  const currentProject = useSelector((state: RootState) => state.project.project)
  const videos = useSelector((state: RootState) => state.allVideos.videos)
  const currentProjectGroups = useSelector((state: RootState) => state.project.groups)
  const videoCompleted = useMemo(() => {
    return videos.find(v => v.id === video?.id)
  }, [video?.id, videos])

  const submitForm: SubmitHandler<IFormInputs> = useCallback(data => {
    console.log(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const groupOptions = useMemo(() => {
    const result = [{ text: 'Без группы', value: 0 }]
    const currentGroupsOptions = currentProjectGroups?.map(group => ({ text: group.name, value: group.id }))
    if (currentGroupsOptions) {
      result.push(...currentGroupsOptions)
    }
    return result
  }, [currentProjectGroups])

  const videoStatus = useMemo(() => {
    switch (video?.status) {
      case 'ok':
        return 'загружено'
      case 'error':
        return 'ошибка'
      case 'pending':
        return 'создано'
      case 'processing':
        return 'обработка'
      default:
        return ''
    }
  }, [video?.status])

  return (
    <BaseModal isOpen={!!video} onClose={onClose} className={style.modal} disabled={false}>
      <FormProvider {...methods}>
        <form className={style.form} onSubmit={methods.handleSubmit(submitForm)} autoComplete="off">
          <div className={style.title}>Параметры видео</div>
          <div className={style.flexRow}>
            <Dropdown
              className={style.dropdown}
              placeholderClassName={style.placeholder}
              onClickOption={() => {}}
              options={[]}
              disabled={true}
              description="Название проекта"
              currentOption={{ text: currentProject?.name || '', value: currentProject?.id }}
            />
            <Dropdown
              className={clsx(style.dropdown, style.dropdownGroup)}
              placeholderClassName={style.placeholder}
              onClickOption={() => {}}
              options={[]}
              disabled={true}
              description="Группа:"
              currentOption={groupOptions.find(group => group.value === video?.group_id) || groupOptions[0]}
            />
          </div>
          <TextFieldStyled
            value={video?.url_video || ''}
            label="Ссылка на *.MP4"
            disabled={video?.status !== 'error'}
          />
          <TextFieldStyled
            value={video?.url_video || ''}
            label="Ссылка на *.SRT"
            disabled={video?.status !== 'error'}
          />
          <div className={style.flexRow}>
            <TextFieldStyled
              className={style.inputDate}
              value={
                video?.updated_at ? dateFnsFormat(new Date(video.updated_at), 'dd.MM.yyyy HH:mm', { locale: ru }) : ''
              }
              label="Дата создания"
              disabled
            />
            <TextFieldStyled
              className={style.inputAuthor}
              value={video?.updated_by.email || ''}
              label="Пользователь"
              disabled
            />
            <TextFieldStyled className={style.inputStatus} value={videoStatus} label="Статус" disabled />
          </div>
          {video?.status === 'ok' && videoCompleted && (
            <>
              <div className={style.flexRow}>
                <TextFieldStyled
                  className={style.inputDate}
                  value={
                    videoCompleted.date
                      ? dateFnsFormat(new Date(videoCompleted.date), 'dd.MM.yyyy HH:mm', { locale: ru })
                      : ''
                  }
                  label="Дата съемки"
                  disabled
                />
                <TextFieldStyled
                  className={style.inputAuthor}
                  value={videoCompleted.file_md5 || ''}
                  label="MD5"
                  disabled
                />
                <TextFieldStyled
                  className={style.inputStatus}
                  value={prettyBytes(videoCompleted.file_size || 0, { locale: 'ru' })}
                  label="Размер"
                  disabled
                />
              </div>
              <TextFieldStyled
                className={style.inputStatus}
                value={JSON.stringify(videoCompleted.info)}
                label="Подробности"
                disabled
                multiline
              />
            </>
          )}
          <div className={style.buttons}>
            <Button.Standart className={clsx(style.button, style.buttonClose)} onClick={onClose} disabled={false}>
              Закрыть
            </Button.Standart>
            {video?.status === 'ok' ? (
              <Button.Standart
                className={clsx(style.button)}
                loading={false}
                onClick={() =>
                  navigate(generatePath(PATH.VIDEO, { projectId: String(currentProject?.id), id: String(video.id) }))
                }
              >
                Показать
              </Button.Standart>
            ) : (
              <Button.Standart className={clsx(style.button)} disabled={true} loading={false}>
                Сохранить
              </Button.Standart>
            )}
          </div>
        </form>
      </FormProvider>
    </BaseModal>
  )
}

export default EditVideoModal
