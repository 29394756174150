import { useState } from 'react'
import { FormHelperText, TextField } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from 'components/Button/Button'
import { useUser } from 'hooks/user'
import { useCallback, useMemo } from 'react'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { PATH, PROGRESS_TYPES } from 'utils/constants'
import { emailSchema, passwordSchema } from 'utils/validationSchema'
import * as yup from 'yup'
import logo from 'images/logo.png'
import style from './Signin.module.css'
import { Link } from 'react-router-dom'
import logoOneSrc from 'images/new/logo1.png'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Footer from './Footer/Footer'

interface IFormInputs {
  email: string
  password: string
}

const Signin: React.FC = () => {
  const user = useUser()

  const [showPassword, setShowPassword] = useState(false)

  const loginProgress = useMemo(() => user?.state.loginProgress, [user?.state.loginProgress])
  const methods = useForm<IFormInputs>({
    resolver: yupResolver(yup.object().shape({ email: emailSchema(), password: passwordSchema() })),
  })

  const onSubmit: SubmitHandler<IFormInputs> = useCallback(
    data => {
      user?.actions.signin(data.email, data.password)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <div className={style.wrapper}>
      <div className={style.name}>
        <img className={style.logoImg} src={logoOneSrc} alt="logo" />
        <p>Сервис по мониторингу объектов строительства</p>
      </div>
      <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off" className={style.signinBlock}>
        <img src={logo} alt="logo" className={style.logo} />
        <div className={style.enterText}>Войдите в ваш аккаунт</div>
        <div className={style.fld}>
          <TextField
            {...methods.register('email')}
            id="email"
            name="email"
            variant="standard"
            autoComplete="username"
            inputProps={{
              'data-test-id': 'email',
            }}
            aria-describedby="email-error-text"
            error={!!methods.formState.errors.email}
            label="Логин"
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
          <FormHelperText id="email-error-text">
            {methods.formState.errors.email?.message ? 'Invalid email' : ''}
          </FormHelperText>
        </div>
        <div className={style.fld}>
          <TextField
            {...methods.register('password')}
            id="password"
            name="password"
            type={showPassword ? 'text' : 'password'}
            variant="standard"
            autoComplete="password"
            inputProps={{
              'data-test-id': 'password',
            }}
            aria-describedby="password-error-text"
            error={!!methods.formState.errors.password}
            label="Пароль"
            InputLabelProps={{ shrink: true }}
            fullWidth
          />

          <div className={style.eye} onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </div>

          <FormHelperText id="password-error-text">
            {methods.formState.errors.password?.message ? 'Invalid password' : ''}
          </FormHelperText>
        </div>
        <Link className={style.forgotPasswordLink} to={PATH.RESTORE_PASSWORD}>
          Забыли пароль?
        </Link>
        <Button.Standart
          dataTestId={'submit-btn'}
          disabled={false}
          loading={loginProgress === PROGRESS_TYPES.WORK}
          className={style.signinButton}
        >
          {'Вход'}
        </Button.Standart>
      </form>
      <div className={style.description}>Контроль строительства на новой высоте</div>
      <Footer />
    </div>
  )
}

export default Signin
