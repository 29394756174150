import { Project, Video } from 'api/schema'
import { RootState } from 'utils/interfaces'
import dateFnsFormat from 'date-fns/format'
import ru from 'date-fns/locale/ru'
import { useSelector } from 'react-redux'
import { groupBy } from 'lodash'
import VideosGrouped from './VideosGrouped/VideosGrouped'
import style from './ProjectInfo.module.css'
import { useState } from 'react'

enum GroupingType {
  BY_DATE = 'date',
  BY_GROUP = 'group',
}

const ProjectInfo = ({ project }: { project: Project }) => {
  const projectId = useSelector((state: RootState) => state.project.project?.id)
  const groups = useSelector((state: RootState) => state.project.groups)
  const [grouping, setGrouping] = useState<GroupingType>(GroupingType.BY_DATE)

  const videosGroupedByDates = useSelector((state: RootState) => {
    const videos = state.allVideos.videos.filter(video => video.project_id === project.id)
    const result = groupBy(videos, (video: Video) =>
      dateFnsFormat(new Date(video.date || ''), 'dd LLL yyyy', { locale: ru })
    )
    return result
  })

  const videoGroupedByGroups = useSelector((state: RootState) => {
    const videos = state.allVideos.videos.filter(video => video.project_id === project.id)
    const result = groupBy(
      videos.filter(video => !!video.group_id),
      (video: Video) => {
        const group = groups?.find(group => group.id === video.group_id)
        return group?.name || 'Без группы'
      }
    )
    return result
  })

  const videoGrouped = grouping === GroupingType.BY_DATE ? videosGroupedByDates : videoGroupedByGroups

  return (
    <div className={style.projectInfo}>
      <div className={style.groupingSwitcherBlock}>
        Отображать по:
        <span
          className={grouping === GroupingType.BY_DATE ? style.active : style.inactive}
          onClick={() => setGrouping(GroupingType.BY_DATE)}
        >
          Датам
        </span>
        <span
          className={grouping === GroupingType.BY_GROUP ? style.active : style.inactive}
          onClick={() => setGrouping(GroupingType.BY_GROUP)}
        >
          Группам
        </span>
      </div>
      {!!Object.keys(videoGrouped).length &&
        Object.keys(videoGrouped).map(name => (
          <VideosGrouped groupName={name} videos={videoGrouped[name]} key={name} projectId={projectId || 0} />
        ))}
      {grouping === GroupingType.BY_GROUP && Object.keys(videoGroupedByGroups).length === 0 && (
        <div className={style.noData}>В проекте нет групп</div>
      )}
      {grouping === GroupingType.BY_DATE && Object.keys(videosGroupedByDates).length === 0 && (
        <div className={style.noData}>В проекте нет видео</div>
      )}
    </div>
  )
}

export default ProjectInfo
