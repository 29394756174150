import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { MainProjectService } from 'api/schema'
import { ProjectActions } from 'store/actions/project'
import { PROGRESS_TYPES } from 'utils/constants'
import { RootState } from 'utils/interfaces'

const fetchProject = (projectId: number) => {
  return async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    try {
      dispatch(ProjectActions.setLoadingProgress(PROGRESS_TYPES.WORK))

      const projectRaw = await MainProjectService.mainProjectRetrieve(projectId)
      dispatch(ProjectActions.setDetailedProject(projectRaw))

      const picketsRaw = await MainProjectService.mainProjectMarkList([projectId])
      dispatch(ProjectActions.setPickets(picketsRaw))

      const groupsRaw = await MainProjectService.mainVideoGroupList(undefined, [projectId])
      dispatch(ProjectActions.setGroups(groupsRaw))

      dispatch(ProjectActions.setLoadingProgress(PROGRESS_TYPES.SUCCESS))
    } catch (error) {
      dispatch(ProjectActions.setLoadingProgress(PROGRESS_TYPES.ERROR))
    }
  }
}

const resetProject = () => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(ProjectActions.setLoadingProgress(PROGRESS_TYPES.IDLE))
    dispatch(ProjectActions.setDetailedProject())
  }
}

export const projectThunks = {
  fetchProject,
  resetProject,
}
