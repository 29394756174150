import { createSlice } from '@reduxjs/toolkit'
import { VideoFrame, VideoRead } from 'api/schema'
import { PROGRESS_TYPES } from 'utils/constants'
import { IVideoState } from 'utils/interfaces'

const initialState: IVideoState = {
  loadingProgress: PROGRESS_TYPES.IDLE,
  fetchingFramesProgress: PROGRESS_TYPES.IDLE,
  video: undefined,
  frames: [],
  showOtherVideos: false,
  isPlaying: true,
  inited: false,
  frameNumber: 0,
  playbackRate: 1,
  showPickets: false,
  editingAnnotationId: undefined,
}

export const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    setLoadingProgress: (state: IVideoState, action: { payload: PROGRESS_TYPES }) => {
      state.loadingProgress = action.payload
    },
    setFetchingVideosProgress: (state: IVideoState, action: { payload: PROGRESS_TYPES }) => {
      state.fetchingFramesProgress = action.payload
    },
    setVideo: (state: IVideoState, action: { payload: VideoRead | undefined }) => {
      state.video = action.payload
    },
    setFrames: (state: IVideoState, action: { payload: Array<VideoFrame> }) => {
      state.frames = action.payload
    },
    toggleShowOtherVideos: (state: IVideoState) => {
      state.showOtherVideos = !state.showOtherVideos
    },
    setShowOtherVideos: (state: IVideoState, action: { payload: boolean }) => {
      state.showOtherVideos = action.payload
    },
    setFrameNumber: (state: IVideoState, action: { payload: number }) => {
      state.frameNumber = action.payload
    },
    setPlayingState: (state: IVideoState, action: { payload: boolean }) => {
      state.isPlaying = action.payload
    },
    setInited: (state: IVideoState, action: { payload: boolean }) => {
      state.inited = action.payload
    },
    setEditingAnnotationId: (state: IVideoState, action: { payload: number | undefined }) => {
      state.editingAnnotationId = action.payload
    },
    setRate(state: IVideoState, action: { payload: number }) {
      if (state.video) {
        state.playbackRate = action.payload
      }
    },
    setShowPickets: (state: IVideoState, action: { payload: boolean }) => {
      state.showPickets = action.payload
    },
    setInitalState: (state: IVideoState) => {
      state.loadingProgress = initialState.loadingProgress
      state.fetchingFramesProgress = initialState.fetchingFramesProgress
      state.video = initialState.video
      state.frames = initialState.frames
      state.showOtherVideos = initialState.showOtherVideos
      state.isPlaying = initialState.isPlaying
      state.inited = initialState.inited
      state.frameNumber = initialState.frameNumber
      state.editingAnnotationId = initialState.editingAnnotationId
    },
  },
})

// Action creators are generated for each case reducer function
export const VideoActions = videoSlice.actions

export default videoSlice.reducer
