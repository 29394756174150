import TopLeftMenu from './TopLeftMenu/TopLeftMenu'
import MainMap from './MainMap/MainMap'
import ButtonLogout from './RightButtons/ButtonLogout'
import ButtonQuestion from './RightButtons/ButtonQuestion'

import style from './Main.module.css'

const Main: React.FC = () => {
  return (
    <>
      <MainMap />
      <div className={style.projects}>
        <TopLeftMenu />
      </div>
      <ButtonLogout />
      <ButtonQuestion />
    </>
  )
}

export default Main
