import MapboxCommon from 'components/MapboxMap/MapboxCommon'
import Raster from 'components/MapLayers/Raster'
import { useSelector } from 'react-redux'
import { IMapboxElement, IPoint, ITrajectories, RootState } from 'utils/interfaces'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { zoomMapTo } from 'utils/mapUtils'
import SumRouteLineOnMap from './Layers/SumRouteLineOnMap'
import nearestPointOnLine from '@turf/nearest-point-on-line'
import { generatePath, useNavigate } from 'react-router-dom'
import { PATH } from 'utils/constants'
import Pickets from 'components/MapLayers/Pickets'
import CustomGeometry from 'components/MapLayers/CustomGeometry'
import style from './MainMap.module.css'
import { MainProjectVideoService, VideoFrameSearch } from 'api/schema'

const MainMap = () => {
  const navigate = useNavigate()
  const currentProjectDetailed = useSelector((state: RootState) => state.project.project)
  const pickets = useSelector((state: RootState) => state.project.pickets)

  const mapRef = useRef<IMapboxElement>(null)

  const sumRouteLine = useMemo<ITrajectories>(() => {
    return {
      type: 'Feature',
      geometry: {
        type: 'MultiLineString',
        coordinates: currentProjectDetailed?.route?.coordinates || [[[0, 0]]],
      },
      properties: {
        id_on_map: 'sumRoute',
      },
    }
  }, [currentProjectDetailed?.route?.coordinates])

  const onMapInited = useCallback(() => {
    if (currentProjectDetailed) {
      zoomMapTo(mapRef.current?.getMap(), [sumRouteLine])
    }
  }, [currentProjectDetailed, sumRouteLine])

  useEffect(() => {
    onMapInited()
  }, [onMapInited])

  const onRouteClicked = useCallback(
    async (coords: mapboxgl.LngLat) => {
      if (!currentProjectDetailed) {
        return
      }
      const nearestPoint = nearestPointOnLine(currentProjectDetailed?.route, [coords.lng, coords.lat]) as IPoint
      try {
        const videosRaw = await MainProjectVideoService.mainVideoFrameSearchList(
          [currentProjectDetailed.id],
          5,
          undefined,
          // Грязный хак из-за того, что codegen неправильно реализует спецификацию OpenAPI 3.0.3: при style=form и explode=false массив чисел должен в запросе идти через запятую
          [`${nearestPoint.geometry.coordinates}` as unknown as number]
        )
        videosRaw.sort((a: VideoFrameSearch, b: VideoFrameSearch) => {
          if (a.video.date && b.video.date) {
            return new Date(b.video.date).getTime() - new Date(a.video.date).getTime()
          }
          return 0
        })
        if (videosRaw[0] && currentProjectDetailed) {
          const path =
            generatePath(PATH.VIDEO, {
              projectId: String(currentProjectDetailed.id),
              id: String(videosRaw[0].video_id),
            }) +
            '?t=' +
            videosRaw[0].timestamp_seconds
          navigate(path)
        }
      } catch (error) {
        console.error(error)
      }
    },
    [currentProjectDetailed, navigate]
  )

  return (
    <div className={style.map}>
      <MapboxCommon ref={mapRef} onMapInited={onMapInited}>
        {/* <CenterLineOnMap centerLine={centerLineFeature} /> */}
        <Raster
          key={currentProjectDetailed?.layer_top || 'raster-1'}
          url={currentProjectDetailed?.layer_top || ''}
          id="raster-1"
          // beforeId="centerline-style"
        />
        <Raster
          key={currentProjectDetailed?.layer_bottom || 'raster-2'}
          url={currentProjectDetailed?.layer_bottom || ''}
          id="raster-2"
          // beforeId="centerline-style"
        />
        <CustomGeometry geoJson={currentProjectDetailed?.layer_geometry} color={currentProjectDetailed?.layer_color} />
        <SumRouteLineOnMap map={mapRef.current?.getMap()} sumRouteLine={sumRouteLine} onRouteClicked={onRouteClicked} />
        <Pickets pickets={pickets || []} />
      </MapboxCommon>
    </div>
  )
}

export default MainMap
