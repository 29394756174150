import { VideoImportReadFromRemoteStorage } from 'api/schema'
import { Button } from 'components/Button/Button'
import style from './ImportVideoList.module.css'

import { ReactComponent as SuccessSvg } from 'images/icons/success.svg'
import { ReactComponent as ErrorSvg } from 'images/icons/error.svg'
import { ReactComponent as ProcessingSvg } from 'images/icons/refresh.svg'
import AddVideoModal from './AddVideoModal/AddVideoModal'
import dateFnsFormat from 'date-fns/format'
import ru from 'date-fns/locale/ru'
import { useMemo, useState } from 'react'
import EditVideoModal from './EditVideoModal/EditVideoModal'
import { useSelector } from 'react-redux'
import { RootState } from 'utils/interfaces'

const VideoImport = ({
  video,
  onClicked,
}: {
  video: VideoImportReadFromRemoteStorage
  onClicked: (video: VideoImportReadFromRemoteStorage) => void
}) => {
  const icon = useMemo(() => {
    switch (video.status) {
      case 'ok':
        return <SuccessSvg />
      case 'error':
        return <ErrorSvg />
      case 'pending':
        return <ProcessingSvg />
      case 'processing':
        return <ProcessingSvg />
      default:
        return null
    }
  }, [video.status])

  const filename = video.url_video.split('/').pop()

  const status = useMemo(() => {
    switch (video.status) {
      case 'ok':
        return 'загружено'
      case 'error':
        return 'ошибка'
      case 'pending':
        return 'создано'
      case 'processing':
        return 'обработка'
      default:
        return null
    }
  }, [video.status])

  return (
    <div className={style.video} onClick={() => onClicked(video)}>
      <div>{icon}</div>
      <div>
        <div className={style.filename}>{filename}</div>
        <div className={style.date}>
          {dateFnsFormat(new Date(video.updated_at || ''), 'dd.MM.yyyy HH:mm', { locale: ru })}
        </div>
      </div>
      <div className={style.statusContainer}>
        <div className={style.status}>{status}</div>
        <div className={style.email}>{video.updated_by.email}</div>
      </div>
    </div>
  )
}

const ImportVideoList = ({
  imports,
  onUpdateList,
}: {
  imports: VideoImportReadFromRemoteStorage[]
  onUpdateList: () => void
}) => {
  const total = imports.length
  const errored = imports.filter(imp => imp.status === 'error').length
  const inProcess = imports.filter(imp => imp.status === 'processing').length
  const [addVideoModalOpen, setAddVideoModalOpen] = useState(false)
  const [editingVideoImport, setEditingVideoImport] = useState<VideoImportReadFromRemoteStorage | undefined>()
  const currentProject = useSelector((state: RootState) => state.project.project)

  const openVideoEditModal = (video: VideoImportReadFromRemoteStorage) => {
    setEditingVideoImport(video)
  }
  const onVideoAdded = () => {
    setAddVideoModalOpen(false)
    onUpdateList()
  }
  return (
    <>
      <div className={style.info}>
        <span className={style.total}>Всего: {total}</span>
        <span className={style.errors}>Ошибок: {errored}</span>
        <span className={style.inProcess}>В процессе: {inProcess}</span>
        {currentProject?.current_user_data.can_add_video && (
          <Button.Standart className={style.button} onClick={() => setAddVideoModalOpen(true)}>
            Добавить
          </Button.Standart>
        )}
      </div>
      <div className={style.scrollableList}>
        {imports.map(video => (
          <VideoImport video={video} onClicked={openVideoEditModal} key={video.id} />
        ))}
      </div>
      <AddVideoModal isOpen={addVideoModalOpen} onClose={() => setAddVideoModalOpen(false)} onAdd={onVideoAdded} />
      <EditVideoModal onClose={() => setEditingVideoImport(undefined)} videoImport={editingVideoImport} />
    </>
  )
}

export default ImportVideoList
