import Dropdown, { IOption } from 'components/Dropdown/Dropdown'
import { ReactComponent as DeleteSvg } from 'images/icons/delete.svg'
import { RootState } from 'utils/interfaces'
import { useSelector } from 'react-redux'
import dateFnsFormat from 'date-fns/format'
import ru from 'date-fns/locale/ru'
import { useMemo, useState } from 'react'
import { Button } from 'components/Button/Button'
import { projectThunks } from 'store/thunks/project'
import store from 'store/store'
import { ITempPoint } from '../RouteMarking'

import style from './InfoBlock.module.css'
import toTimestamp from 'utils/secondsToTimestamp'
import clsx from 'clsx'
import savePointsToCsv from '../savePointsToCsv'
import { videoThunks } from 'store/thunks/video'

const InfoBlock = ({
  points,
  selectedPoint,
  onDeletePoint,
  onPointClicked,
}: {
  points: Array<ITempPoint>
  selectedPoint?: ITempPoint
  onDeletePoint: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => void
  onPointClicked: (point: ITempPoint) => void
}) => {
  const projects = useSelector((state: RootState) => state.allProjects.projects)
  const dropdownProjects = useMemo<Array<IOption>>(() => {
    return [
      { text: 'Выберите проект...', value: undefined },
      ...projects.map(project => {
        return { text: project.name, value: project.id }
      }),
    ]
  }, [projects])
  const [currentProjectOption, setCurrentProjectOption] = useState<IOption>(dropdownProjects[0])

  const videos = useSelector((state: RootState) =>
    state.allVideos.videos.filter(video => currentProjectOption && video.project_id === currentProjectOption.value)
  )
  const currentVideo = useSelector((state: RootState) => state.video.video)

  const dropdownVideos = useMemo<Array<IOption>>(
    () => [
      ...videos.map(video => {
        return { text: video.name || video.id.toString(), value: video.id }
      }),
    ],
    [videos]
  )
  const [currentVideoOption, setCurrentVideoOption] = useState<IOption>(dropdownVideos[0])

  const onProjectSelected = (e: IOption) => {
    setCurrentProjectOption(e)
    setCurrentVideoOption({ text: 'Выберите видео...' })
    if (e && e.value) {
      store.dispatch(projectThunks.fetchProject(e.value as number))
    } else {
      store.dispatch(projectThunks.resetProject())
    }
  }

  const onVideoSelected = (e: IOption) => {
    setCurrentVideoOption(e)
    const video = videos.find(video => video.id === e.value)
    if (video) {
      store.dispatch(videoThunks.fetchVideo(video.id))
    }
  }

  const onSaveClicked = () => {
    if (currentVideo) {
      savePointsToCsv(`${currentVideo.project_id}_${currentVideo.name}`, points)
    }
  }

  return (
    <div className={style.infoBlock}>
      <div className={style.selectProjectAndVideoBlock}>
        <Dropdown
          className={style.dropdown}
          onClickOption={onProjectSelected}
          options={dropdownProjects}
          currentOption={currentProjectOption}
        />
        {currentProjectOption && currentProjectOption.value && (
          <Dropdown
            className={style.dropdown}
            onClickOption={onVideoSelected}
            options={dropdownVideos}
            currentOption={currentVideoOption}
          />
        )}
      </div>
      {currentVideo && (
        <>
          <div>
            <div className={style.date}>
              Дата съёмки: {dateFnsFormat(new Date(currentVideo.date || ''), 'dd LLL yyyy HH:MM', { locale: ru })}
            </div>
            <div className={style.flexRow}>
              <Button.Standart className={style.button}>Рассчитать</Button.Standart>
              <Button.Standart className={style.button} onClick={onSaveClicked}>
                Сохранить
              </Button.Standart>
            </div>
          </div>
          <div className={style.pointList}>
            {points.map((point, index) => (
              <div
                key={index}
                className={point === selectedPoint ? clsx(style.point, style.pointActive) : style.point}
                onClick={() => onPointClicked(point)}
              >
                <span>{toTimestamp(point.frameNumber)}</span>
                <span>
                  {point.lat.toFixed(3)}, {point.long.toFixed(3)}
                </span>
                <Button.Standart className={style.iconButton} onClick={e => onDeletePoint(e, index)}>
                  <DeleteSvg />
                </Button.Standart>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default InfoBlock
