// import { Header } from 'components/Header/Header'
import { Outlet } from 'react-router-dom'

import style from './Layout.module.css'

function MainPageLayout() {
  return (
    <>
      {/* <div className={style.header}>
        <Header.Main />
      </div> */}
      <div className={style.container}>
        <Outlet />
      </div>
    </>
  )
}

function PublicPageLayout() {
  return (
    <>
      {/* <div className={style.header}>
        <Header.Public />
      </div> */}
      <div className={style.container}>
        <Outlet />
      </div>
    </>
  )
}

export const Layout = {
  MainPage: MainPageLayout,
  PublicPage: PublicPageLayout,
}
