import { Button } from 'components/Button/Button'
import { ReactComponent as LeftArrowSvg } from 'images/icons/arrowLeft.svg'

import style from './ButtonBack.module.css'

const ButtonBack = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button.Outlined className={style.button} onClick={onClick}>
      <LeftArrowSvg />
    </Button.Outlined>
  )
}

export default ButtonBack
