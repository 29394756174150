import { Button } from 'components/Button/Button'
import { ReactComponent as QuestionSvg } from 'images/icons/questionMark.svg'

import style from './RightButtons.module.css'

const ButtonLogout = () => {
  return (
    <a href="/api/assets/aeroinspector.pdf" target="_blank" rel="noreferrer">
      <Button.Outlined className={style.buttonQuestion}>
        <QuestionSvg />
      </Button.Outlined>
    </a>
  )
}

export default ButtonLogout
