/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedVideoImportReadFromRemoteStorageList } from '../models/PaginatedVideoImportReadFromRemoteStorageList'
import type { VideoImportFromRemoteStorage } from '../models/VideoImportFromRemoteStorage'
import type { VideoImportFromRemoteStorageRequest } from '../models/VideoImportFromRemoteStorageRequest'
import type { VideoImportReadFromRemoteStorage } from '../models/VideoImportReadFromRemoteStorage'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class MainProjectVideoImportService {
  /**
   * @param ordering Which field to use when ordering the results.
   * @param page A page number within the paginated result set.
   * @param pageSize Number of results to return per page.
   * @param projectId
   * @param videoId
   * @returns PaginatedVideoImportReadFromRemoteStorageList
   * @throws ApiError
   */
  public static mainVideoImportFromRemoteStorageList(
    ordering?: Array<'id' | '-id' | 'status' | '-status' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at'>,
    page?: number,
    pageSize?: number,
    projectId?: Array<number>,
    videoId?: Array<number | null>
  ): CancelablePromise<PaginatedVideoImportReadFromRemoteStorageList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/video-import-from-remote-storage/',
      query: {
        ordering: ordering,
        page: page,
        page_size: pageSize,
        project_id: projectId,
        video_id: videoId,
      },
    })
  }

  /**
   * @param requestBody
   * @returns VideoImportFromRemoteStorage
   * @throws ApiError
   */
  public static mainVideoImportFromRemoteStorageCreate(
    requestBody: VideoImportFromRemoteStorageRequest
  ): CancelablePromise<VideoImportFromRemoteStorage> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/main/video-import-from-remote-storage/',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id A unique integer value identifying this импорт видео.
   * @returns VideoImportReadFromRemoteStorage
   * @throws ApiError
   */
  public static mainVideoImportFromRemoteStorageRetrieve(
    id: number
  ): CancelablePromise<VideoImportReadFromRemoteStorage> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/video-import-from-remote-storage/{id}/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this импорт видео.
   * @returns void
   * @throws ApiError
   */
  public static mainVideoImportFromRemoteStorageDestroy(id: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/main/video-import-from-remote-storage/{id}/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this импорт видео.
   * @returns VideoImportReadFromRemoteStorage
   * @throws ApiError
   */
  public static mainVideoImportFromRemoteStorageProcessingStartCreate(
    id: number
  ): CancelablePromise<VideoImportReadFromRemoteStorage> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/main/video-import-from-remote-storage/{id}/processing-start/',
      path: {
        id: id,
      },
    })
  }
}
