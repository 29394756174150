/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Email } from '../models/Email'
import type { EmailRequest } from '../models/EmailRequest'
import type { LoginRequest } from '../models/LoginRequest'
import type { PasswordChangeRequest } from '../models/PasswordChangeRequest'
import type { PasswordToken } from '../models/PasswordToken'
import type { PasswordTokenRequest } from '../models/PasswordTokenRequest'
import type { PatchedWhoAmIRequest } from '../models/PatchedWhoAmIRequest'
import type { ResetToken } from '../models/ResetToken'
import type { ResetTokenRequest } from '../models/ResetTokenRequest'
import type { StatusResponse } from '../models/StatusResponse'
import type { UserRegistrationRequest } from '../models/UserRegistrationRequest'
import type { WhoAmI } from '../models/WhoAmI'
import type { WhoAmIRead } from '../models/WhoAmIRead'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class AccService {
  /**
   * @param requestBody
   * @returns WhoAmIRead
   * @throws ApiError
   */
  public static accLoginCreate(requestBody: LoginRequest): CancelablePromise<WhoAmIRead> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/acc/login/',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @returns StatusResponse
   * @throws ApiError
   */
  public static accLogoutCreate(): CancelablePromise<StatusResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/acc/logout/',
    })
  }

  /**
   * @param requestBody
   * @returns StatusResponse
   * @throws ApiError
   */
  public static accPasswordChangeCreate(requestBody: PasswordChangeRequest): CancelablePromise<StatusResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/acc/password-change/',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * An Api View which provides a method to request a password reset token based on an e-mail address
   *
   * Sends a signal reset_password_token_created when a reset token was created
   * @param requestBody
   * @returns Email
   * @throws ApiError
   */
  public static accPasswordResetCreate(requestBody: EmailRequest): CancelablePromise<Email> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/acc/password-reset/',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * An Api View which provides a method to reset a password based on a unique token
   * @param requestBody
   * @returns PasswordToken
   * @throws ApiError
   */
  public static accPasswordResetConfirmCreate(requestBody: PasswordTokenRequest): CancelablePromise<PasswordToken> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/acc/password-reset/confirm/',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * An Api View which provides a method to verify that a token is valid
   * @param requestBody
   * @returns ResetToken
   * @throws ApiError
   */
  public static accPasswordResetValidateTokenCreate(requestBody: ResetTokenRequest): CancelablePromise<ResetToken> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/acc/password-reset/validate_token/',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param requestBody
   * @returns WhoAmI
   * @throws ApiError
   */
  public static accRegistrationCreate(requestBody: UserRegistrationRequest): CancelablePromise<WhoAmI> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/acc/registration/',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @returns WhoAmIRead
   * @throws ApiError
   */
  public static accWhoamiRetrieve(): CancelablePromise<WhoAmIRead> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/acc/whoami/',
    })
  }

  /**
   * @param requestBody
   * @returns WhoAmI
   * @throws ApiError
   */
  public static accWhoamiPartialUpdate(requestBody?: PatchedWhoAmIRequest): CancelablePromise<WhoAmI> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/acc/whoami/',
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
