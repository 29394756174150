import { VideoRead } from 'api/schema'
import { WheelEventHandler, createRef, useCallback, useEffect, useState } from 'react'
import { SeekFrameCustomEvent } from 'utils/interfaces'
import videojs, { VideoJsPlayer } from 'video.js'
import 'video.js/dist/video-js.css'
import '@theonlyducks/videojs-zoom/styles'
import '@theonlyducks/videojs-zoom'
import './VideoPlayer.css'

type VideoPlayerProps = {
  video?: VideoRead
  playbackRate?: number
  autoplay?: boolean
  onTimeUpdate?: (time: number) => void
  onPlayingStateChange?: (state: boolean) => void
  setInited?: (state: boolean) => void
  onVideoEnded?: () => void
  onPlaybackRateChanged?: (rate: number) => void
}

const VideoPlayer = ({
  video,
  playbackRate = 1,
  autoplay = true,
  onTimeUpdate,
  onPlayingStateChange,
  setInited,
  onVideoEnded,
  onPlaybackRateChanged,
}: VideoPlayerProps) => {
  const videoNodeRef = createRef<HTMLVideoElement>()
  const [player, setPlayer] = useState<VideoJsPlayer | null>()
  const [zoomPlugin, setZoomPlugin] = useState<any>()
  const [isMouseDown, setIsMouseDown] = useState(false)
  const [isMouseMoved, setIsMouseMoved] = useState(false)

  useEffect(() => {
    if (!player && videoNodeRef.current) {
      const player = videojs(
        videoNodeRef.current,
        {
          responsive: true,
          controls: true,
          fill: true,
          controlBar: {
            pictureInPictureToggle: false,
            fullscreenToggle: false,
          },
          playbackRates: [0.5, 1, 2, 4, 8],
          autoplay,
          muted: true,
        },
        () => {
          setInited && setInited(true)
        }
      )
      video?.file && player.src(video?.file)
      const zoomPlugin = (player as any).zoomPlugin()
      zoomPlugin.listen('change', (options: any) => {
        if (options.zoom > 5) {
          zoomPlugin.zoom(5)
        }
      })
      setPlayer(player)
      setZoomPlugin(zoomPlugin)
    }
    return () => {
      setInited && setInited(false)
    }
  }, [])

  const pausePlayer = useCallback(() => {
    player?.pause()
  }, [player])

  const seekFrame: EventListener = useCallback(
    (evt: SeekFrameCustomEvent) => {
      evt.detail && player?.currentTime(evt.detail)
    },
    [player]
  )

  const seekFrameForward: EventListener = useCallback(() => {
    player?.currentTime(player.currentTime() + 10)
  }, [player])

  const seekFrameBackward: EventListener = useCallback(() => {
    player?.currentTime(player.currentTime() - 10)
  }, [player])

  const togglePlay: EventListener = useCallback(() => {
    if (player?.paused()) {
      player?.play()
    } else {
      player?.pause()
    }
  }, [player])

  useEffect(() => {
    window.addEventListener('videoPausedEvent', pausePlayer)
    window.addEventListener('videoSeekFrameEvent', seekFrame)
    window.addEventListener('videoSeekForwardEvent', seekFrameForward)
    window.addEventListener('videoSeekBackwardEvent', seekFrameBackward)
    window.addEventListener('videoTogglePlayEvent', togglePlay)
    return () => {
      window.removeEventListener('videoPausedEvent', pausePlayer)
      window.removeEventListener('videoSeekFrameEvent', seekFrame)
      window.removeEventListener('videoSeekForwardEvent', seekFrameForward)
      window.removeEventListener('videoSeekBackwardEvent', seekFrameBackward)
      window.removeEventListener('videoTogglePlayEvent', togglePlay)
    }
  }, [pausePlayer, seekFrame, seekFrameBackward, seekFrameForward, togglePlay])

  useEffect(() => {
    const onTimeUpdateCb = () => {
      player && onTimeUpdate?.(player.currentTime())
    }

    const onPlayCb = () => {
      onPlayingStateChange && onPlayingStateChange(true)
    }

    const onPauseCb = () => {
      onPlayingStateChange && onPlayingStateChange(false)
    }

    const onEndCb = () => {
      onVideoEnded && onVideoEnded()
    }

    const onRateChangedCb = () => {
      player && onPlaybackRateChanged && onPlaybackRateChanged(player.playbackRate())
    }

    const onLoadedDataCb = () => {
      player && player.playbackRate(playbackRate)
    }

    const onTouchStartCb = () => {
      if (!player) {
        return
      }
      if (player.paused()) {
        player.play()
      } else {
        player.pause()
      }
    }

    player?.on('timeupdate', onTimeUpdateCb)
    player?.on('play', onPlayCb)
    player?.on('pause', onPauseCb)
    player?.on('ended', onEndCb)
    player?.on('ratechange', onRateChangedCb)
    player?.on('loadeddata', onLoadedDataCb)
    player?.on('touchstart', onTouchStartCb)
    return () => {
      player?.off('timeupdate', onTimeUpdateCb)
      player?.off('play', onPlayCb)
      player?.off('pause', onPauseCb)
      player?.off('ended', onEndCb)
      player?.off('ratechange', onRateChangedCb)
      player?.off('ratechange', onLoadedDataCb)
      player?.off('touchstart', onTouchStartCb)
    }
  }, [onPlayingStateChange, onTimeUpdate, onVideoEnded, player, onPlaybackRateChanged, playbackRate])

  useEffect(() => {
    if (player && player.src && video?.file) {
      player.src(video.file)
    }
  }, [player, video?.file])

  const onWheel = useCallback<WheelEventHandler<HTMLVideoElement>>(
    (evt: React.WheelEvent<HTMLVideoElement>) => {
      const newZoom = zoomPlugin.state.zoom - evt.deltaY / 1000
      if (newZoom > 1 && newZoom < 10) {
        zoomPlugin.zoom(newZoom)
      }
    },
    [zoomPlugin]
  )

  const onMouseDown = useCallback((evt: React.MouseEvent<HTMLVideoElement>) => {
    if (evt.button !== 2) {
      setIsMouseDown(true)
    }
  }, [])

  const onMouseUp = useCallback(
    (evt: React.MouseEvent<HTMLVideoElement>) => {
      setIsMouseDown(false)
      if (!isMouseMoved && evt.button === 1) {
        zoomPlugin.zoom(1)
        zoomPlugin.move(0, 0)
      }
    },
    [isMouseMoved, zoomPlugin]
  )

  // Нужно, чтобы при перемещении картинки не срабатывал плей/пауза
  const onClick = useCallback(
    (evt: React.MouseEvent<HTMLVideoElement>) => {
      if (isMouseMoved) {
        if (player?.paused()) {
          player.play()
        } else {
          player?.pause()
        }
      }
    },
    [isMouseMoved, player]
  )

  const onContextMenu = useCallback(
    (evt: React.MouseEvent<HTMLVideoElement>) => {
      evt.preventDefault()
      if (player?.paused()) {
        player.play()
      } else {
        player?.pause()
      }
    },
    [player]
  )

  const onMouseMove = useCallback(
    (evt: React.MouseEvent<HTMLVideoElement>) => {
      if (isMouseDown) {
        const newX = zoomPlugin.state.moveX + evt.movementX
        const newY = zoomPlugin.state.moveY + evt.movementY
        const screenWidth = window.innerWidth
        const screenHeight = window.innerHeight
        if (newX > -screenWidth / 2 && newY > -screenHeight / 2 && newX < screenWidth / 2 && newY < screenHeight / 2) {
          zoomPlugin.move(zoomPlugin.state.moveX + evt.movementX, zoomPlugin.state.moveY + evt.movementY)
        }
        if (evt.movementX !== 0 || evt.movementY !== 0) {
          setIsMouseMoved(true)
        }
      } else {
        setIsMouseMoved(false)
      }
    },
    [isMouseDown, zoomPlugin]
  )

  return (
    <div data-vjs-player>
      <video
        ref={videoNodeRef}
        className="video-js vjs-big-play-centered"
        id="my-video"
        onWheel={onWheel}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        onClick={onClick}
        onContextMenu={onContextMenu}
      />
    </div>
  )
}

export default VideoPlayer
