import { useState } from 'react'
import style from './CarouselImages.module.css'
import { ReactComponent as ArrowLeft } from 'images/icons/arrowLeft.svg'
import { ReactComponent as ArrowRight } from 'images/icons/arrowRight.svg'
import dateFnsFormat from 'date-fns/format'
import clsx from 'clsx'

type CarouselImagesProps = {
  imagesUrls: Array<string | null | undefined>
  dateTimes: Array<string | null | undefined>
  currentPhotoIndex: number
  onCurrentPhotoIndexChange?: (index: number) => void
}

const CarouselImages = ({
  imagesUrls,
  dateTimes,
  currentPhotoIndex,
  onCurrentPhotoIndexChange,
}: CarouselImagesProps) => {
  const [activeImage, setActiveImage] = useState(currentPhotoIndex)
  const images = imagesUrls
    .slice(0)
    .reverse()
    .map((image, index) =>
      image ? (
        <img key={index} src={image} alt="Video preview" className={style.imgFull} />
      ) : (
        <div className={style.noImage}>Нет изображения</div>
      )
    )
  const oneImageAtLeast = imagesUrls.filter(image => image !== null).length > 0
  const twoImagesAtLeast = imagesUrls.filter(image => image !== null).length > 1

  const onLeftArrowClick = () => {
    if (activeImage === 0) {
      setActiveImage(images.length - 1)
    } else {
      setActiveImage(activeImage - 1)
    }
  }

  const onRightArrowClick = () => {
    if (activeImage === images.length - 1) {
      setActiveImage(0)
      onCurrentPhotoIndexChange && onCurrentPhotoIndexChange(0)
    } else {
      setActiveImage(activeImage + 1)
      onCurrentPhotoIndexChange && onCurrentPhotoIndexChange(activeImage + 1)
    }
  }

  return (
    <div className={style.carouselImages}>
      {twoImagesAtLeast && <ArrowLeft className={clsx(style.arrow, style.arrowLeft)} onClick={onLeftArrowClick} />}
      {images[activeImage]}
      {twoImagesAtLeast && <ArrowRight className={clsx(style.arrow, style.arrowRight)} onClick={onRightArrowClick} />}
      {twoImagesAtLeast && (
        <span className={style.imageCounter}>
          {activeImage + 1}/{images.length}
        </span>
      )}
      {oneImageAtLeast && (
        <span className={style.dateTime}>
          {dateTimes[activeImage] && dateFnsFormat(new Date(dateTimes[activeImage] || ''), 'dd.MM.yyyy HH:mm')}
        </span>
      )}
    </div>
  )
}

export default CarouselImages
