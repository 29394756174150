import { Button } from 'components/Button/Button'
import { ReactComponent as LogoutSvg } from 'images/icons/logout.svg'
import { useUser } from 'hooks/user'
import { useCallback } from 'react'

import style from './RightButtons.module.css'

const ButtonLogout = () => {
  const user = useUser()

  const onClick = useCallback(() => {
    user?.actions.signout()
  }, [user?.actions])

  return (
    <Button.Outlined className={style.button} onClick={onClick}>
      <LogoutSvg />
    </Button.Outlined>
  )
}

export default ButtonLogout
