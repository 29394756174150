import { useMemo } from 'react'
import { Layer, LineLayer, Source } from 'react-map-gl'

const CustomGeometry = ({ geoJson, color = '#000' }: { geoJson?: Record<string, any> | null; color?: string }) => {
  const style = useMemo<LineLayer>(() => {
    return {
      id: 'custom-geometry-style',
      type: 'line',
      layout: {
        'line-cap': 'square',
      },
      paint: {
        'line-width': 1,
        'line-color': color,
        'line-opacity': 1,
      },
    }
  }, [color])

  const validJsonString = useMemo(() => {
    try {
      const str = JSON.stringify(geoJson)
      JSON.parse(str)
      return true
    } catch (e) {
      return false
    }
  }, [geoJson])

  return validJsonString && geoJson ? (
    <>
      <Source id={'customGeometry'} type="geojson" data={geoJson as GeoJSON.FeatureCollection<GeoJSON.Geometry>}>
        <Layer {...style} />
      </Source>
    </>
  ) : null
}

export default CustomGeometry
