import { Feature } from 'geojson'
import { bbox } from 'turf'
import { MapboxMap } from 'react-map-gl'

export const zoomMapTo = (map: MapboxMap | undefined, features: Array<Feature>) => {
  if (!map) {
    return
  }
  const [minLng, minLat, maxLng, maxLat] = bbox({
    type: 'FeatureCollection',
    features: features,
  })
  if (!isFinite(minLng)) {
    return
  }
  map.fitBounds(
    [
      [minLng, minLat],
      [maxLng, maxLat],
    ],
    { padding: 40, duration: 1000, maxZoom: 20 }
  )
}

export const centerMapTo = (map: MapboxMap | undefined, features: Array<Feature>) => {
  if (!map) {
    return
  }
  const [minLng, minLat, maxLng, maxLat] = bbox({
    type: 'FeatureCollection',
    features: features,
  })
  if (!isFinite(minLng)) {
    return
  }
  if (!map.isRotating() && !map.isEasing() && !map.isZooming()) {
    map.flyTo({ animate: true, duration: 500, center: [(maxLng + minLng) / 2, (maxLat + minLat) / 2] })
  }
}
