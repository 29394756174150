import { useMemo } from 'react'
import { Layer, LineLayer, Source } from 'react-map-gl'
import { ITrajectory } from 'utils/interfaces'

const VideoLineOnMap = ({ videoLine }: { videoLine: ITrajectory }) => {
  const transferRouteStyle = useMemo<LineLayer>(() => {
    return {
      id: 'video-line-style',
      type: 'line',
      layout: {
        'line-cap': 'square',
      },
      paint: {
        'line-width': 1,
        'line-color': '#C4C4C4',
        'line-opacity': 1,
      },
    }
  }, [])

  return (
    <Source id="videoline" type="geojson" data={videoLine}>
      <Layer {...transferRouteStyle} />
    </Source>
  )
}

export default VideoLineOnMap
