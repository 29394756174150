import { yupResolver } from '@hookform/resolvers/yup'
import { TextField } from '@mui/material'
import { Button } from 'components/Button/Button'
import { useUser } from 'hooks/user'
import { useCallback, useMemo } from 'react'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { PATH, PROGRESS_TYPES } from 'utils/constants'
import getTestId from 'utils/getTestId'
import { emailSchema } from 'utils/validationSchema'
import logo from 'images/logo.png'
import logoOneSrc from 'images/new/logo1.png'
import * as yup from 'yup'
import clsx from 'clsx'

import style from '../Signin.module.css'
import Footer from '../Footer/Footer'

interface IFormInputs {
  email: string
}

const RestorePassword: React.FC = () => {
  const user = useUser()

  const restoreProgress = useMemo(() => user?.state.restorePasswordProgress, [user?.state.restorePasswordProgress])
  const methods = useForm<IFormInputs>({
    resolver: yupResolver(yup.object().shape({ email: emailSchema() })),
  })

  const onSubmit: SubmitHandler<IFormInputs> = useCallback(
    data => {
      user?.actions.restorePassword(data.email)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <div className={style.wrapper}>
      <div className={style.name}>
        <img className={style.logoImg} src={logoOneSrc} alt="logo" />
        <p>Сервис по мониторингу объектов строительства</p>
      </div>
      <form className={style.signinBlock} onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
        {restoreProgress !== PROGRESS_TYPES.SUCCESS && (
          <>
            <img src={logo} alt="logo" className={style.logo} />
            <div className={style.enterText}>Введите e-mail:</div>
            <div className={style.fld}>
              <TextField
                {...methods.register('email')}
                id="email"
                name="email"
                variant="standard"
                autoComplete="username"
                placeholder="Электронная почта"
                inputProps={{
                  'data-test-id': 'email',
                }}
                aria-describedby="email-error-text"
                error={!!methods.formState.errors.email || restoreProgress === PROGRESS_TYPES.ERROR}
                fullWidth
              />
              {/* <FormHelperText id="email-error-text">Неверный формат e-mail</FormHelperText> */}
            </div>
            <Button.Standart
              dataTestId={'submit-btn'}
              disabled={restoreProgress === PROGRESS_TYPES.WORK}
              loading={restoreProgress === PROGRESS_TYPES.WORK}
              className={clsx(style.signinButton, style.restorePasswordButton)}
            >
              Сбросить пароль
            </Button.Standart>
          </>
        )}
        {restoreProgress === PROGRESS_TYPES.SUCCESS && (
          <>
            <img src={logo} alt="logo" className={style.logo} />
            <div className={style.restoreText}>Письмо с ссылкой на восстановление пароля отправлено!</div>
          </>
        )}
        <span data-test-id={'restore'} className={style.restoreText}>
          Уже есть логин и пароль?
          <Link className={style.forgotPasswordLink} to={PATH.SIGNIN} data-test-id={getTestId('link-forgot-password')}>
            Войти
          </Link>
        </span>
      </form>
      <div className={style.description}>Контроль строительства на новой высоте</div>
      <Footer />
    </div>
  )
}

export default RestorePassword
