import { ReactComponent as FilterSvg } from 'images/icons/filter.svg'
import { ReactComponent as DownArrowSvg } from 'images/icons/arrowDown.svg'
import { ReactComponent as UpArrowSvg } from 'images/icons/arrowUp.svg'
import { ReactComponent as CheckFilterSvg } from 'images/icons/checkFilter.svg'
import { Popover } from '@mui/material'
import { useRef, useState } from 'react'
import { ITaskFilters } from 'utils/interfaces'
import style from './TaskFilter.module.css'

const FiltersList = ({
  filters,
  onFiltersChanged,
}: {
  filters: ITaskFilters
  onFiltersChanged: (filters: ITaskFilters) => void
}) => {
  const onActiveClicked = (e: React.MouseEvent) => {
    e.stopPropagation()
    const newFilters = { ...filters }
    newFilters.status = newFilters.status === 'active' ? 'all' : 'active'
    onFiltersChanged(newFilters)
  }

  const onCompletedClicked = (e: React.MouseEvent) => {
    e.stopPropagation()
    const newFilters = { ...filters }
    newFilters.status = newFilters.status === 'completed' ? 'all' : 'completed'
    onFiltersChanged(newFilters)
  }

  const onMyTasksClicked = (e: React.MouseEvent) => {
    e.stopPropagation()
    const newFilters = { ...filters }
    newFilters.user = newFilters.user === 'me' ? 'all' : 'me'
    onFiltersChanged(newFilters)
  }

  const onVideoClicked = (e: React.MouseEvent) => {
    e.stopPropagation()
    const newFilters = { ...filters }
    newFilters.video = newFilters.video === 'current' ? 'all' : 'current'
    onFiltersChanged(newFilters)
  }

  const onInTimeClicked = (e: React.MouseEvent) => {
    e.stopPropagation()
    const newFilters = { ...filters }
    newFilters.date = newFilters.date === 'inTime' ? 'all' : 'inTime'
    onFiltersChanged(newFilters)
  }

  const onOutdatedClicked = (e: React.MouseEvent) => {
    e.stopPropagation()
    const newFilters = { ...filters }
    newFilters.date = newFilters.date === 'outdated' ? 'all' : 'outdated'
    onFiltersChanged(newFilters)
  }

  return (
    <div className={style.filtersList}>
      <div className={style.filterBlock}>
        <div className={style.filterTitle}>По статусу:</div>
        <div className={style.filterItem} onClick={onActiveClicked}>
          Активные {filters.status === 'active' && <CheckFilterSvg className={style.check} />}
        </div>
        <div className={style.filterItem} onClick={onCompletedClicked}>
          Завершенные {filters.status === 'completed' && <CheckFilterSvg className={style.check} />}
        </div>
      </div>
      <div className={style.filterBlock}>
        <div className={style.filterTitle}>По пользователю:</div>
        <div className={style.filterItem} onClick={onMyTasksClicked}>
          Только мои {filters.user === 'me' && <CheckFilterSvg className={style.check} />}
        </div>
      </div>
      <div className={style.filterBlock}>
        <div className={style.filterTitle}>По видео:</div>
        <div className={style.filterItem} onClick={onVideoClicked}>
          Только это видео {filters.video === 'current' && <CheckFilterSvg className={style.check} />}
        </div>
      </div>
      <div className={style.filterBlock}>
        <div className={style.filterTitle}>По дате:</div>
        <div className={style.filterItem} onClick={onInTimeClicked}>
          Не просроченные {filters.date === 'inTime' && <CheckFilterSvg className={style.check} />}
        </div>
        <div className={style.filterItem} onClick={onOutdatedClicked}>
          Просроченные {filters.date === 'outdated' && <CheckFilterSvg className={style.check} />}
        </div>
      </div>
    </div>
  )
}

const TaskFilter = ({
  filters,
  onFiltersChanged,
}: {
  filters: ITaskFilters
  onFiltersChanged: (filters: ITaskFilters) => void
}) => {
  const [showFilters, setShowFilters] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)
  const filtersArray: string[] = []
  const filterText = () => {
    if (filters.status === 'active') {
      filtersArray.push('Активные')
    } else if (filters.status === 'completed') {
      filtersArray.push('Завершенные')
    }
    if (filters.user === 'me') {
      filtersArray.push('Только мои')
    }
    if (filters.date === 'outdated') {
      filtersArray.push('Просроченные')
    } else if (filters.date === 'inTime') {
      filtersArray.push('Не просроченные')
    }
    if (filters.video === 'current') {
      filtersArray.push('Только это видео')
    }
    const result = filtersArray.length ? filtersArray.join(', ') : 'Все задачи'
    return result
  }

  return (
    <div className={style.row} onClick={() => setShowFilters(!showFilters)} ref={ref}>
      <FilterSvg />
      <div className={style.text}>{filterText()}</div>
      {showFilters ? <UpArrowSvg /> : <DownArrowSvg />}
      <Popover
        open={showFilters}
        anchorEl={ref.current}
        onClose={() => setShowFilters(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            marginTop: '10px',
            borderRadius: '0px',
          },
        }}
      >
        <FiltersList filters={filters} onFiltersChanged={onFiltersChanged} />
      </Popover>
    </div>
  )
}

export default TaskFilter
