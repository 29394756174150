import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Button } from 'components/Button/Button'
import { ReactComponent as MoreIconSvg } from 'images/icons/three-dots-vertical.svg'
import { useState } from 'react'

import style from './ActionsMenu.module.css'

type ActionsMenuItemProps = {
  onClick?: React.MouseEventHandler<HTMLLIElement>
}

export function ActionsMenuItem(props: React.PropsWithChildren<ActionsMenuItemProps>) {
  return (
    <>
      <MenuItem
        className={style.menuItem}
        sx={{
          pl: 0,
          height: 40,
        }}
        onClick={props.onClick}
      >
        {props.children}
      </MenuItem>
    </>
  )
}

function ActionsMenu(props: any) {
  const [anchorEl, setAnchorEl] = useState(null)
  const showActionsMenu = Boolean(anchorEl)

  const onShowActionsClicked = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }

  const onCloseActions = (e: any) => {
    e.stopPropagation()
    setAnchorEl(null)
  }

  const onItemClick = (e: any) => {
    e.stopPropagation()
    setAnchorEl(null)
  }

  return (
    <>
      <Button.Standart
        className={style.btnShowActions}
        onClick={onShowActionsClicked}
        dataTestId={'button-more-action'}
      >
        <MoreIconSvg />
      </Button.Standart>
      <Menu
        className={style.menu}
        classes={{ list: style.menuList }}
        anchorEl={anchorEl}
        open={showActionsMenu}
        onClose={onCloseActions}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            minWidth: '160px',
          },
        }}
        MenuListProps={{ sx: { pt: 0.5 } }}
        onClick={onItemClick}
      >
        {props.children}
      </Menu>
    </>
  )
}

export default ActionsMenu
