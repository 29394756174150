import MapboxCommon from 'components/MapboxMap/MapboxCommon'
import PointOnMap from './Layers/PointOnMap'
import VideoLineOnMap from './Layers/VideoLineOnMap'
import SumRouteLineOnMap from './Layers/SumRouteLineOnMap'
import CenterLineOnMap from 'pages/Main/MainMap/Layers/CenterlineOnMap'
import { centerMapTo } from 'utils/mapUtils'
import { useSelector } from 'react-redux'
import { IMapboxElement, IPoint, ITrajectories, ITrajectory, RootState } from 'utils/interfaces'
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import nearestPointOnLine from '@turf/nearest-point-on-line'
import store from 'store/store'
import { videoThunks } from 'store/thunks/video'
import VideoRouteOnMap from './Layers/VideoRouteOnMap'
import Raster from 'components/MapLayers/Raster'
import Pickets from 'components/MapLayers/Pickets'
import { Button } from 'components/Button/Button'
import clsx from 'clsx'
import { debounce } from 'lodash'
import { ResizableBox, ResizeCallbackData } from 'react-resizable'
import CustomGeometry from 'components/MapLayers/CustomGeometry'
import { DraggableCore, DraggableData, DraggableEvent } from 'react-draggable'

import style from './MiniMap.module.css'
import 'react-resizable/css/styles.css'

const MiniMap = () => {
  const mapRef = useRef<IMapboxElement>(null)
  const resizableBoxRef = useRef<HTMLDivElement>(null)

  const frames = useSelector((state: RootState) => state.video.frames)
  const video = useSelector((state: RootState) => state.video.video)
  const videoPlaying = useSelector((state: RootState) => state.video.isPlaying)
  const frameNumber = useSelector((state: RootState) => state.video.frameNumber)
  const project = useSelector((state: RootState) => state.project.project)
  const pickets = useSelector((state: RootState) => state.project.pickets)
  const [pointCoords, setPointCoords] = useState<IPoint>()
  const [pointBinded, setPointBinded] = useState<boolean>(true)
  const [miniMapCoords, setMiniMapCoords] = useState<[number, number]>([15, 320])
  const [maxConstraints, setMaxConstraints] = useState<[number, number]>([800, 280])
  const [collapsed, setCollapsed] = useState<boolean>(false)

  const map = mapRef.current?.getMap()
  const mapInited = map?.loaded()

  useEffect(() => {}, [mapInited])

  const onCenterClicked = () => {
    setPointBinded(true)
    if (pointCoords) {
      centerMapTo(map, [pointCoords])
    }
  }

  const debouncedMapDragging = useCallback(debounce(onCenterClicked, 5000), [])

  const onMapDragged = () => {
    setPointBinded(false)
    if (videoPlaying) {
      debouncedMapDragging()
    }
  }

  useEffect(() => {
    map?.on('dragstart', onMapDragged)
    return () => {
      map?.off('dragstart', onMapDragged)
    }
  }, [map])

  useEffect(() => {
    if (videoPlaying) {
      debouncedMapDragging()
    } else {
      debouncedMapDragging.cancel()
    }
  }, [debouncedMapDragging, videoPlaying])

  useEffect(() => {
    const pointDisplay = frames[frameNumber] ? frames[frameNumber].point_display : { coordinates: [0, 0] }
    setPointCoords({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: pointDisplay.coordinates || [0, 0],
      },
      properties: {
        id_on_map: 'point',
      },
    })
  }, [frames, frameNumber])

  useEffect(() => {
    if (pointCoords && pointBinded) {
      centerMapTo(mapRef.current?.getMap(), [pointCoords])
    }
  }, [pointBinded, pointCoords, mapInited])

  const videoLine = useMemo<ITrajectory>(() => {
    return {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: video?.geometry?.coordinates || [[0, 0]],
      },
      properties: {
        id_on_map: 'videoLine',
      },
    }
  }, [video])

  const videoRoute = useMemo<ITrajectories>(() => {
    return {
      type: 'Feature',
      geometry: {
        type: 'MultiLineString',
        coordinates: video?.route?.coordinates || [[[0, 0]]],
      },
      properties: {
        id_on_map: 'videoRoute',
      },
    }
  }, [video])

  const sumRouteLine = useMemo<ITrajectories>(() => {
    return {
      type: 'Feature',
      geometry: {
        type: 'MultiLineString',
        coordinates: project?.route?.coordinates || [[[0, 0]]],
      },
      properties: {
        id_on_map: 'sumRoute',
      },
    }
  }, [project?.route])

  const centerLine = useMemo<ITrajectory>(() => {
    return {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: project?.central_line?.coordinates || [[0, 0]],
      },
      properties: {
        id_on_map: 'centerLine',
      },
    }
  }, [project])

  const onRouteClicked = useCallback(
    (coords: mapboxgl.LngLat) => {
      const nearestPoint = nearestPointOnLine(videoRoute, [coords.lng, coords.lat]) as IPoint
      store.dispatch(videoThunks.seekVideoByCoords(nearestPoint.geometry.coordinates))
    },
    [videoRoute]
  )

  const onResize = (e: SyntheticEvent, data: ResizeCallbackData) => {
    e.stopPropagation()
    const current = mapRef.current
    current?.getMap()?.resize()
  }

  const onDrag = (e: DraggableEvent, data: DraggableData) => {
    const newX = miniMapCoords[0] + data.deltaX
    const newY = miniMapCoords[1] - data.deltaY
    const mapWindowWidth = resizableBoxRef.current?.clientWidth || 300
    const mapWindowHeight = resizableBoxRef.current?.clientHeight || 200
    if (
      newX < 0 ||
      newY < mapWindowHeight + 40 ||
      newX > window.innerWidth - mapWindowWidth ||
      newY > window.innerHeight
    ) {
      return
    }
    setMiniMapCoords([newX, newY])
    setMaxConstraints([window.innerWidth - newX - 10, newY - 50])
  }

  const onCollapseClicked = () => {
    setCollapsed(!collapsed)
  }

  return (
    <>
      <DraggableCore onDrag={onDrag} handle={'.draggable'}>
        <ResizableBox
          className={style.resizableBox}
          style={{
            left: `${miniMapCoords[0]}px`,
            bottom: `${miniMapCoords[1]}px`,
            visibility: collapsed ? 'hidden' : 'visible',
          }}
          width={Math.min(370, window.innerWidth - 40)}
          height={280}
          onResize={onResize}
          minConstraints={[200, 150]}
          maxConstraints={maxConstraints}
        >
          <div className={style.map} ref={resizableBoxRef}>
            <div className={style.draggableButton + ' draggable'}>
              <div className={style.draggableIcon} />
            </div>
            <MapboxCommon ref={mapRef}>
              <CustomGeometry geoJson={project?.layer_geometry} color={project?.layer_color} />
              <CenterLineOnMap centerLine={centerLine} />
              <Raster url={project?.layer_top || ''} id="raster-1" beforeId="centerline-style" />
              <Raster url={project?.layer_bottom || ''} id="raster-2" beforeId="centerline-style" />
              <SumRouteLineOnMap sumRouteLine={sumRouteLine} />
              <VideoLineOnMap videoLine={videoLine} />
              <VideoRouteOnMap
                map={mapRef.current?.getMap()}
                videoRouteLine={videoRoute}
                onRouteClicked={onRouteClicked}
              />
              <Pickets pickets={pickets || []} />
              <PointOnMap centerPoint={pointCoords} />
              <Button.Standart className={style.centerButton} onClick={onCenterClicked}>
                <span className={clsx('mapboxgl-ctrl-icon', style.centerIcon)} title="Центрировать" />
              </Button.Standart>
              <Button.Standart className={style.collapseButton} onClick={onCollapseClicked}>
                <span className={clsx('mapboxgl-ctrl-icon', style.collapseIcon)} title="Свернуть" />
              </Button.Standart>
            </MapboxCommon>
          </div>
        </ResizableBox>
      </DraggableCore>
      {collapsed && (
        <Button.Standart className={style.expandButton} onClick={onCollapseClicked}>
          <span className={clsx('mapboxgl-ctrl-icon', style.expandIcon)} title="Развернуть" />
        </Button.Standart>
      )}
    </>
  )
}

export default MiniMap
