const toTimestamp = (seconds: number) => {
  const hours: number = Math.floor(seconds / 3600)
  const minutes: number = Math.floor((seconds % 3600) / 60)
  const remainingSeconds: number = seconds % 60

  const formattedHours: string = String(hours).padStart(2, '0')
  const formattedMinutes: string = String(minutes).padStart(2, '0')
  const formattedSeconds: string = String(remainingSeconds).padStart(2, '0')

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
}

export default toTimestamp
