import { TextField } from '@mui/material'
import style from './TextFieldStyled.module.css'
import clsx from 'clsx'

interface ITextFieldStyledProps {
  value: string
  label?: string
  disabled?: boolean
  className?: string
  multiline?: boolean
}

const TextFieldStyled = ({ value, label, disabled = false, className, multiline = false }: ITextFieldStyledProps) => {
  return (
    <div className={clsx(style.fld, className)}>
      {label && <div className={style.description}>{label}</div>}
      <TextField
        id="linkSrt"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        disabled={disabled}
        value={value}
        sx={{
          '& .MuiInputBase-input': {
            height: '40px',
            padding: '0 14px',
            fontSize: '14px',
          },
          '& .MuiFilledInput-input': {
            height: '40px',
          },
          '& .Mui-disabled': {
            bgcolor: '#DADDE5A6',
          },
          '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: '#DADDE5',
          },
          '& .Mui-disabled textarea': {
            bgcolor: '#00000000',
            padding: '0 0',
            height: 'auto',
          },
          overflow: 'auto',
        }}
        fullWidth
        multiline={multiline}
        rows={5}
      />
    </div>
  )
}

export default TextFieldStyled
