import { MainProjectVideoImportService, PaginatedVideoImportReadFromRemoteStorageList } from 'api/schema'
import Loader from 'components/Loader/Loader'
import { useEffect, useMemo, useState } from 'react'
import { PROGRESS_TYPES } from 'utils/constants'
import ImportVideoList from './ImportVideoList'

const ImportVideoListContainer = ({ currentProjectId }: { currentProjectId: number }) => {
  const [loadingState, setLoadingState] = useState(PROGRESS_TYPES.IDLE)
  const [importsList, setImportsList] = useState<PaginatedVideoImportReadFromRemoteStorageList>()
  const importsForProject = useMemo(
    () => importsList?.results?.filter(imp => imp.project_id === currentProjectId),
    [importsList, currentProjectId]
  )

  const fetchImports = async () => {
    try {
      setLoadingState(PROGRESS_TYPES.WORK)
      const importsResp = await MainProjectVideoImportService.mainVideoImportFromRemoteStorageList(
        ['-updated_at'],
        undefined,
        undefined,
        [currentProjectId]
      )
      setImportsList(importsResp)
      setLoadingState(PROGRESS_TYPES.SUCCESS)
    } catch (error) {
      setLoadingState(PROGRESS_TYPES.ERROR)
    }
  }

  useEffect(() => {
    fetchImports()
  }, [])

  useEffect(() => {
    fetchImports()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProjectId])

  return (
    <div>
      {loadingState === PROGRESS_TYPES.WORK && <Loader />}
      {loadingState === PROGRESS_TYPES.SUCCESS && importsForProject && (
        <ImportVideoList imports={importsForProject} onUpdateList={fetchImports} />
      )}
      {loadingState === PROGRESS_TYPES.ERROR && 'Loading error'}
    </div>
  )
}

export default ImportVideoListContainer
