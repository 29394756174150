import { createSlice } from '@reduxjs/toolkit'
import { ProjectDetailed, ProjectMark, VideoGroupReadList } from 'api/schema'
import { PROGRESS_TYPES } from 'utils/constants'
import { IProjectState } from 'utils/interfaces'

const initialState: IProjectState = {
  loadingProgress: PROGRESS_TYPES.IDLE,
  fetchingVideosProgress: PROGRESS_TYPES.IDLE,
  project: undefined,
  pickets: undefined,
  groups: undefined,
}

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setLoadingProgress: (state: IProjectState, action: { payload: PROGRESS_TYPES }) => {
      state.loadingProgress = action.payload
    },
    setDetailedProject: (state: IProjectState, action: { payload: ProjectDetailed | undefined }) => {
      state.project = action.payload
    },
    setPickets: (state: IProjectState, action: { payload: Array<ProjectMark> | undefined }) => {
      state.pickets = action.payload
    },
    setGroups: (state: IProjectState, action: { payload: Array<VideoGroupReadList> | undefined }) => {
      state.groups = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const ProjectActions = projectSlice.actions

export default projectSlice.reducer
