import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { MainProjectVideoService } from 'api/schema'
import { VideoActions } from 'store/actions/video'
import { PROGRESS_TYPES } from 'utils/constants'
import { RootState } from 'utils/interfaces'

const fetchVideo = (videoId: number) => {
  return async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    try {
      dispatch(VideoActions.setLoadingProgress(PROGRESS_TYPES.WORK))

      const videoRaw = await MainProjectVideoService.mainVideoRetrieve(videoId)
      dispatch(VideoActions.setVideo(videoRaw))

      const framesRaw = await MainProjectVideoService.mainVideoFrameList(
        undefined,
        [videoRaw.project_id],
        undefined,
        undefined,
        [videoId]
      )
      dispatch(VideoActions.setFrames(framesRaw))

      dispatch(VideoActions.setLoadingProgress(PROGRESS_TYPES.SUCCESS))
    } catch (error) {
      dispatch(VideoActions.setLoadingProgress(PROGRESS_TYPES.ERROR))
    }
  }
}

const seekVideoByCoords = (coords: number[]) => {
  return async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    try {
      const videoId = getState().video.video?.id
      const projectId = getState().video.video?.project_id
      const frameRaw = await MainProjectVideoService.mainVideoFrameSearchList(
        projectId ? [projectId] : [],
        15,
        undefined,
        [`${coords}` as unknown as number],
        videoId ? [videoId] : undefined
      )
      window.dispatchEvent(
        new CustomEvent('videoSeekFrameEvent', { detail: Math.round(frameRaw[0].timestamp_seconds) })
      )
    } catch (error) {}
  }
}

export const videoThunks = {
  fetchVideo,
  seekVideoByCoords,
}
