/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedProjectList } from '../models/PaginatedProjectList'
import type { ProjectDetailed } from '../models/ProjectDetailed'
import type { ProjectMark } from '../models/ProjectMark'
import type { VideoGroupReadList } from '../models/VideoGroupReadList'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class MainProjectService {
  /**
   * @param ordering Which field to use when ordering the results.
   * @param page A page number within the paginated result set.
   * @param pageSize Number of results to return per page.
   * @param search A search term.
   * @returns PaginatedProjectList
   * @throws ApiError
   */
  public static mainProjectList(
    ordering?: Array<
      | 'id'
      | '-id'
      | 'name'
      | '-name'
      | 'videos_count'
      | '-videos_count'
      | 'users_count'
      | '-users_count'
      | 'created_at'
      | '-created_at'
      | 'updated_at'
      | '-updated_at'
    >,
    page?: number,
    pageSize?: number,
    search?: string
  ): CancelablePromise<PaginatedProjectList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/project/',
      query: {
        ordering: ordering,
        page: page,
        page_size: pageSize,
        search: search,
      },
    })
  }

  /**
   * @param projectId
   * @param ordering Which field to use when ordering the results.
   * @returns ProjectMark
   * @throws ApiError
   */
  public static mainProjectMarkList(
    projectId: Array<number>,
    ordering?: Array<'id' | '-id' | 'number' | '-number' | 'point' | '-point'>
  ): CancelablePromise<Array<ProjectMark>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/project-mark/',
      query: {
        ordering: ordering,
        project_id: projectId,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this проект.
   * @returns ProjectDetailed
   * @throws ApiError
   */
  public static mainProjectRetrieve(id: number): CancelablePromise<ProjectDetailed> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/project/{id}/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param ordering Which field to use when ordering the results.
   * @param projectId
   * @returns VideoGroupReadList
   * @throws ApiError
   */
  public static mainVideoGroupList(
    ordering?: Array<'id' | '-id' | 'name' | '-name' | 'date_start' | '-date_start' | 'date_end' | '-date_end'>,
    projectId?: Array<number>
  ): CancelablePromise<Array<VideoGroupReadList>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/video-group/',
      query: {
        ordering: ordering,
        project_id: projectId,
      },
    })
  }
}
