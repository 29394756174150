import { Button } from 'components/Button/Button'
import BaseModal from 'components/BaseModal/BaseModal'
import { FormHelperText, TextField } from '@mui/material'
import clsx from 'clsx'
import Dropdown, { IOption } from 'components/Dropdown/Dropdown'
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { urlSchema } from 'utils/validationSchema'
import { useSelector } from 'react-redux'
import { RootState } from 'utils/interfaces'
import { useCallback, useMemo, useState } from 'react'
import { PROGRESS_TYPES } from 'utils/constants'
import style from './AddVideoModal.module.css'
import { MainProjectVideoImportService } from 'api/schema'

interface IFormInputs {
  linkMp4: string
  linkSrt: string
}

const AddVideoModal = ({ isOpen, onClose, onAdd }: { isOpen: boolean; onClose: () => void; onAdd: () => void }) => {
  const currentProject = useSelector((state: RootState) => state.project.project)
  const currentProjectGroups = useSelector((state: RootState) => state.project.groups)
  const [loadingProgress, setLoadingProgress] = useState(PROGRESS_TYPES.IDLE)

  const methods = useForm<IFormInputs>({
    resolver: yupResolver(yup.object().shape({ linkMp4: urlSchema(), linkSrt: urlSchema() })),
  })

  const sendLinks = async (data: IFormInputs, projectId?: number) => {
    try {
      if (projectId === undefined) {
        throw new Error('Не выбран проект')
      }
      setLoadingProgress(PROGRESS_TYPES.WORK)
      const result = await MainProjectVideoImportService.mainVideoImportFromRemoteStorageCreate({
        project_id: projectId,
        url_video: data.linkMp4,
        url_subs: data.linkSrt,
        group_id: currentProjectGroupOption.value ? (currentProjectGroupOption.value as number) : undefined,
      })
      if (!result.error) {
        await MainProjectVideoImportService.mainVideoImportFromRemoteStorageProcessingStartCreate(result.id)
        onAdd()
      }
      setLoadingProgress(PROGRESS_TYPES.SUCCESS)
    } catch (error) {
      setLoadingProgress(PROGRESS_TYPES.ERROR)
    }
  }

  const submitForm: SubmitHandler<IFormInputs> = useCallback(
    data => {
      sendLinks(data, currentProject?.id)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [currentProject]
  )

  const groupOptions = useMemo(() => {
    const result = [{ text: 'Без группы', value: 0 }]
    const currentGroupsOptions = currentProjectGroups?.map(group => ({ text: group.name, value: group.id }))
    if (currentGroupsOptions) {
      result.push(...currentGroupsOptions)
    }
    return result
  }, [currentProjectGroups])
  const [currentProjectGroupOption, setCurrentProjectGroupOption] = useState<IOption>(
    groupOptions[1] ? groupOptions[1] : groupOptions[0]
  )

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      disabled={loadingProgress === PROGRESS_TYPES.WORK}
      className={style.modal}
    >
      <FormProvider {...methods}>
        <form className={style.form} onSubmit={methods.handleSubmit(submitForm)} autoComplete="off">
          <div className={style.title}>Новое видео</div>
          <div className={style.flexRow}>
            <Dropdown
              className={style.dropdown}
              placeholderClassName={style.placeholder}
              onClickOption={() => {}}
              options={[]}
              disabled={true}
              description="Название проекта:"
              currentOption={{ text: currentProject?.name || '', value: currentProject?.id }}
            />
            <Dropdown
              className={clsx(style.dropdown, style.dropdownGroup)}
              placeholderClassName={style.placeholder}
              onClickOption={option => {
                setCurrentProjectGroupOption(option)
              }}
              options={groupOptions}
              description="Группа:"
              currentOption={currentProjectGroupOption}
            />
          </div>
          <div className={style.fld}>
            <div className={style.description}>Ссылка на *.MP4</div>
            <TextField
              {...methods.register('linkMp4')}
              id="linkMp4"
              name="linkMp4"
              variant="outlined"
              aria-describedby="linkMp4-error-text"
              error={!!methods.formState.errors.linkMp4}
              InputLabelProps={{ shrink: true }}
              fullWidth
              sx={{
                height: '40px',
                '& .MuiInputBase-input': {
                  height: '40px',
                  padding: '0 14px',
                },
                '& .MuiFilledInput-input': {
                  height: '40px',
                },
              }}
            />
            <FormHelperText id="linkMp4-error-text">
              {methods.formState.errors.linkMp4?.message ? 'Неверный формат ссылки' : ''}
            </FormHelperText>
          </div>
          <div className={style.fld}>
            <div className={style.description}>Ссылка на *.SRT</div>
            <TextField
              {...methods.register('linkSrt')}
              id="linkSrt"
              name="linkSrt"
              variant="outlined"
              aria-describedby="linkSrt-error-text"
              error={!!methods.formState.errors.linkSrt}
              InputLabelProps={{ shrink: true }}
              fullWidth
              sx={{
                height: '40px',
                '& .MuiInputBase-input': {
                  height: '40px',
                  padding: '0 14px',
                },
                '& .MuiFilledInput-input': {
                  height: '40px',
                },
              }}
            />
            <FormHelperText id="linkSrt-error-text">
              {methods.formState.errors.linkSrt?.message ? 'Неверный формат ссылки' : ''}
            </FormHelperText>
          </div>
          <div className={style.buttons}>
            <Button.Standart
              className={clsx(style.button, style.buttonClose)}
              onClick={onClose}
              disabled={loadingProgress === PROGRESS_TYPES.WORK}
            >
              Закрыть
            </Button.Standart>
            <Button.Standart
              className={clsx(style.button)}
              disabled={loadingProgress === PROGRESS_TYPES.WORK}
              loading={loadingProgress === PROGRESS_TYPES.WORK}
            >
              Сохранить
            </Button.Standart>
          </div>
        </form>
      </FormProvider>
    </BaseModal>
  )
}

export default AddVideoModal
