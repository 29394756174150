/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PatchedVideoAnnotationCommentRequest } from '../models/PatchedVideoAnnotationCommentRequest'
import type { PatchedVideoAnnotationRequest } from '../models/PatchedVideoAnnotationRequest'
import type { VideoAnnotation } from '../models/VideoAnnotation'
import type { VideoAnnotationComment } from '../models/VideoAnnotationComment'
import type { VideoAnnotationCommentRequest } from '../models/VideoAnnotationCommentRequest'
import type { VideoAnnotationReadInstance } from '../models/VideoAnnotationReadInstance'
import type { VideoAnnotationRequest } from '../models/VideoAnnotationRequest'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class MainProjectAnnotationService {
  /**
   * @param dateAfter
   * @param dateBefore
   * @param dist Represents **Distance** in **Distance to point** filter. Default value is used only if ***point*** is passed.
   * @param isExpired
   * @param isResolved
   * @param ordering Which field to use when ordering the results.
   * @param point Point represented in **x,y** format. Represents **point** in **Distance to point filter**
   * @param projectId
   * @param userId
   * @param videoId
   * @returns VideoAnnotationReadInstance
   * @throws ApiError
   */
  public static mainVideoAnnotationList(
    dateAfter?: string,
    dateBefore?: string,
    dist: number = 5,
    isExpired?: boolean,
    isResolved?: boolean,
    ordering?: Array<
      | 'id'
      | '-id'
      | 'first_appeared_at'
      | '-first_appeared_at'
      | 'scheduled_to_resolve_at'
      | '-scheduled_to_resolve_at'
      | 'resolved_at'
      | '-resolved_at'
      | 'created_at'
      | '-created_at'
      | 'updated_at'
      | '-updated_at'
    >,
    point?: Array<number>,
    projectId?: Array<number>,
    userId?: Array<string>,
    videoId?: Array<number>
  ): CancelablePromise<Array<VideoAnnotationReadInstance>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/video-annotation/',
      query: {
        date_after: dateAfter,
        date_before: dateBefore,
        dist: dist,
        is_expired: isExpired,
        is_resolved: isResolved,
        ordering: ordering,
        point: point,
        project_id: projectId,
        user_id: userId,
        video_id: videoId,
      },
    })
  }

  /**
   * @param requestBody
   * @returns VideoAnnotation
   * @throws ApiError
   */
  public static mainVideoAnnotationCreate(requestBody: VideoAnnotationRequest): CancelablePromise<VideoAnnotation> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/main/video-annotation/',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param ordering Which field to use when ordering the results.
   * @param videoAnnotationId
   * @param videoId
   * @returns VideoAnnotationComment
   * @throws ApiError
   */
  public static mainVideoAnnotationCommentList(
    ordering?: Array<'id' | '-id' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at'>,
    videoAnnotationId?: Array<number>,
    videoId?: Array<string>
  ): CancelablePromise<Array<VideoAnnotationComment>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/video-annotation-comment/',
      query: {
        ordering: ordering,
        video_annotation_id: videoAnnotationId,
        video_id: videoId,
      },
    })
  }

  /**
   * @param requestBody
   * @returns VideoAnnotationComment
   * @throws ApiError
   */
  public static mainVideoAnnotationCommentCreate(
    requestBody: VideoAnnotationCommentRequest
  ): CancelablePromise<VideoAnnotationComment> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/main/video-annotation-comment/',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id A unique integer value identifying this комментарий к фрагменту.
   * @returns VideoAnnotationComment
   * @throws ApiError
   */
  public static mainVideoAnnotationCommentRetrieve(id: number): CancelablePromise<VideoAnnotationComment> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/video-annotation-comment/{id}/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this комментарий к фрагменту.
   * @param requestBody
   * @returns VideoAnnotationComment
   * @throws ApiError
   */
  public static mainVideoAnnotationCommentPartialUpdate(
    id: number,
    requestBody?: PatchedVideoAnnotationCommentRequest
  ): CancelablePromise<VideoAnnotationComment> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/main/video-annotation-comment/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id A unique integer value identifying this комментарий к фрагменту.
   * @returns void
   * @throws ApiError
   */
  public static mainVideoAnnotationCommentDestroy(id: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/main/video-annotation-comment/{id}/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this выделенный фрагмент.
   * @returns VideoAnnotationReadInstance
   * @throws ApiError
   */
  public static mainVideoAnnotationRetrieve(id: number): CancelablePromise<VideoAnnotationReadInstance> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/video-annotation/{id}/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this выделенный фрагмент.
   * @param requestBody
   * @returns VideoAnnotation
   * @throws ApiError
   */
  public static mainVideoAnnotationPartialUpdate(
    id: number,
    requestBody?: PatchedVideoAnnotationRequest
  ): CancelablePromise<VideoAnnotation> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/main/video-annotation/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id A unique integer value identifying this выделенный фрагмент.
   * @returns void
   * @throws ApiError
   */
  public static mainVideoAnnotationDestroy(id: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/main/video-annotation/{id}/',
      path: {
        id: id,
      },
    })
  }
}
