import { useMemo } from 'react'
import { Layer, RasterLayer, Source } from 'react-map-gl'

const Raster = ({ id, url, beforeId }: { id: string; url: string; beforeId?: string }) => {
  const rasterStyle = useMemo<RasterLayer>(() => {
    return {
      id: id,
      type: 'raster',
      paint: {},
    }
  }, [id])

  return url ? (
    <>
      {
        <Source id={'id-' + id} type="raster" scheme="tms" tiles={[url]}>
          {beforeId ? <Layer {...rasterStyle} beforeId={beforeId} /> : <Layer {...rasterStyle} />}
        </Source>
      }
    </>
  ) : null
}

export default Raster
