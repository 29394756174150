import { Video } from 'api/schema'
import { useState } from 'react'
import dateFnsFormat from 'date-fns/format'
import ru from 'date-fns/locale/ru'
import { Link, generatePath } from 'react-router-dom'
import { PATH } from 'utils/constants'
import { ReactComponent as ArrowLeftSvg } from 'images/icons/arrowLeft.svg'
import { ReactComponent as ArrowUpSvg } from 'images/icons/arrowUp.svg'
import style from '../ProjectInfo.module.css'
import clsx from 'clsx'

const VideosGrouped = ({ groupName, videos, projectId }: { groupName: string; videos: Video[]; projectId: number }) => {
  const [opened, setOpened] = useState(false)

  return (
    <div className={style.video}>
      <div className={style.groupedDate} onClick={() => setOpened(!opened)}>
        {opened ? (
          <ArrowUpSvg className={clsx(style.blackSvg, style.arrowUp)} />
        ) : (
          <ArrowLeftSvg className={clsx(style.blackSvg, style.arrowRight)} />
        )}
        {groupName}
      </div>
      {opened && (
        <div>
          {videos.map(video => (
            <Link
              className={style.videoLink}
              key={video.id}
              to={generatePath(PATH.VIDEO, { projectId: String(projectId), id: String(video.id) })}
            >
              {dateFnsFormat(new Date(video.date || ''), 'HH:mm', { locale: ru })} (ПК{video.mark_first?.number} - ПК
              {video.mark_last?.number})
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}

export default VideosGrouped
