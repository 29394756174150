import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import store from 'store/store'
import { allProjectsThunks } from 'store/thunks/allProjects'
import { allVideosThunks } from 'store/thunks/allVideos'
import { RootState } from 'utils/interfaces'
import { PROGRESS_TYPES } from 'utils/constants'
import Loader from 'components/Loader/Loader'
import ProjectsList from './ProjectsList/ProjectsList'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { PATH } from 'utils/constants'

import { projectThunks } from 'store/thunks/project'

import style from './TopLeftMenu.module.css'
import { useUser } from 'hooks/user'

const TopLeftMenu = () => {
  const navigate = useNavigate()
  const params = useParams()
  const loadingState = useSelector((state: RootState) => state.allProjects.loadingProgress)
  const projects = useSelector((state: RootState) => state.allProjects.projects)
  const currentProject = useSelector((state: RootState) => state.project.project)
  const user = useUser()

  useEffect(() => {
    const projectId = params.projectId
    if (!projectId && projects.length) {
      navigate(
        generatePath(PATH.MAIN_PROJECT, {
          projectId: user?.state.project_default_id?.toString() || projects[0].id.toString(),
        })
      )
    }
  }, [navigate, params.projectId, projects, user?.state.project_default_id])

  useEffect(() => {
    const projectId = parseFloat(params?.projectId || '')
    if (projectId && projectId !== currentProject?.id) {
      store.dispatch(projectThunks.fetchProject(projectId))
      store.dispatch(allVideosThunks.fetchAllVideos([projectId]))
    }
  }, [currentProject?.id, navigate, params?.projectId])

  useEffect(() => {
    store.dispatch(allProjectsThunks.fetchAllProjects())
  }, [])

  return (
    <div className={style.main}>
      {loadingState === PROGRESS_TYPES.WORK && <Loader />}
      {loadingState === PROGRESS_TYPES.SUCCESS && <ProjectsList />}
      {loadingState === PROGRESS_TYPES.ERROR && <div>Ошибка загрузки</div>}
    </div>
  )
}

export default TopLeftMenu
