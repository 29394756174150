/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedVideoList } from '../models/PaginatedVideoList'
import type { PatchedVideoUpdateRequest } from '../models/PatchedVideoUpdateRequest'
import type { VideoFrame } from '../models/VideoFrame'
import type { VideoFrameSearch } from '../models/VideoFrameSearch'
import type { VideoRead } from '../models/VideoRead'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class MainProjectVideoService {
  /**
   * @param groupId
   * @param ordering Which field to use when ordering the results.
   * @param page A page number within the paginated result set.
   * @param pageSize Number of results to return per page.
   * @param projectId
   * @param search A search term.
   * @returns PaginatedVideoList
   * @throws ApiError
   */
  public static mainVideoList(
    groupId?: Array<number | null>,
    ordering?: Array<
      | 'id'
      | '-id'
      | 'date'
      | '-date'
      | 'status'
      | '-status'
      | 'annotations_count'
      | '-annotations_count'
      | 'created_at'
      | '-created_at'
      | 'updated_at'
      | '-updated_at'
      | 'mark_first__number'
      | '-mark_first__number'
      | 'mark_last__number'
      | '-mark_last__number'
      | 'project_id'
      | '-project_id'
      | 'project__name'
      | '-project__name'
      | 'group_id'
      | '-group_id'
      | 'group__name'
      | '-group__name'
    >,
    page?: number,
    pageSize?: number,
    projectId?: Array<number>,
    search?: string
  ): CancelablePromise<PaginatedVideoList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/video/',
      query: {
        group_id: groupId,
        ordering: ordering,
        page: page,
        page_size: pageSize,
        project_id: projectId,
        search: search,
      },
    })
  }

  /**
   * @param projectId
   * @param dist Represents **Distance** in **Distance to point** filter. Default value is used only if ***point*** is passed.
   * @param ordering Which field to use when ordering the results.
   * @param point Point represented in **x,y** format. Represents **point** in **Distance to point filter**
   * @param videoId
   * @returns VideoFrame
   * @throws ApiError
   */
  public static mainVideoFrameList(
    dist: number | null = null,
    projectId: Array<number>,
    ordering?: Array<
      | 'id'
      | '-id'
      | 'video_id'
      | '-video_id'
      | 'frame'
      | '-frame'
      | 'timestamp'
      | '-timestamp'
      | 'point_display'
      | '-point_display'
      | 'rel_elevation'
      | '-rel_elevation'
      | 'cluster'
      | '-cluster'
      | 'distance'
      | '-distance'
    >,
    point?: Array<number>,
    videoId?: Array<number>
  ): CancelablePromise<Array<VideoFrame>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/video-frame/',
      query: {
        dist: dist,
        ordering: ordering,
        point: point,
        project_id: projectId,
        video_id: videoId,
      },
    })
  }

  /**
   * @param projectId
   * @param dist Represents **Distance** in **Distance to point** filter. Default value is used only if ***point*** is passed.
   * @param ordering Which field to use when ordering the results.
   * @param point Point represented in **x,y** format. Represents **point** in **Distance to point filter**
   * @param videoId
   * @returns VideoFrameSearch
   * @throws ApiError
   */
  public static mainVideoFrameSearchList(
    projectId: Array<number>,
    dist: number = 5,
    ordering?: Array<string>,
    point?: Array<number>,
    videoId?: Array<number>
  ): CancelablePromise<Array<VideoFrameSearch>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/video-frame-search/',
      query: {
        dist: dist,
        ordering: ordering,
        point: point,
        project_id: projectId,
        video_id: videoId,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this видео.
   * @returns VideoRead
   * @throws ApiError
   */
  public static mainVideoRetrieve(id: number): CancelablePromise<VideoRead> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/video/{id}/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this видео.
   * @param requestBody
   * @returns VideoRead
   * @throws ApiError
   */
  public static mainVideoPartialUpdate(
    id: number,
    requestBody?: PatchedVideoUpdateRequest
  ): CancelablePromise<VideoRead> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/main/video/{id}/',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id A unique integer value identifying this видео.
   * @returns void
   * @throws ApiError
   */
  public static mainVideoDestroy(id: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/main/video/{id}/',
      path: {
        id: id,
      },
    })
  }
}
