import { yupResolver } from '@hookform/resolvers/yup'
import { FormHelperText, TextField } from '@mui/material'
import { Button } from 'components/Button/Button'
import { useUser } from 'hooks/user'
import { useCallback, useMemo, useState } from 'react'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { PATH, PROGRESS_TYPES } from 'utils/constants'
import getTestId from 'utils/getTestId'
import { confirmPasswordSchema, newPasswordSchema } from 'utils/validationSchema'
import logo from 'images/logo.png'
import * as yup from 'yup'

import style from '../Signin.module.css'
import { AccService } from 'api/schema/services/AccService'
import clsx from 'clsx'
import Footer from '../Footer/Footer'

interface IFormInputs {
  password: string
  password2: string
}

const NewPassword: React.FC = () => {
  const [progress, setProgress] = useState(PROGRESS_TYPES.IDLE)
  const navigate = useNavigate()

  const user = useUser()
  const [urlParams] = useSearchParams()

  const token = urlParams.get('token')

  const loginProgress = useMemo(() => user?.state.loginProgress, [user?.state.loginProgress])
  const methods = useForm<IFormInputs>({
    resolver: yupResolver(yup.object().shape({ password: newPasswordSchema(), password2: confirmPasswordSchema() })),
  })

  const onSubmit: SubmitHandler<IFormInputs> = useCallback(
    async data => {
      if (data.password !== data.password2) {
        return
      }
      try {
        setProgress(PROGRESS_TYPES.WORK)
        await AccService.accPasswordResetConfirmCreate({ token: token || '', password: data.password })
        setProgress(PROGRESS_TYPES.SUCCESS)
      } catch (error) {
        setProgress(PROGRESS_TYPES.ERROR)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <div className={style.wrapper}>
      <div className={style.name}>
        <span className={style.nameFirstPart}>Аэро</span>инспектор
      </div>
      <form className={style.signinBlock} onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
        <img src={logo} alt="logo" className={style.logo} />
        {progress !== PROGRESS_TYPES.SUCCESS && (
          <>
            <div className={style.enterText}>Введите новый пароль:</div>
            <div className={style.fld}>
              <TextField
                {...methods.register('password')}
                id="password"
                name="password"
                type="password"
                variant="standard"
                inputProps={{
                  'data-test-id': 'password',
                }}
                aria-describedby="password-error-text"
                error={!!methods.formState.errors.password}
                fullWidth
              />
              <FormHelperText id="password-error-text">{methods.formState.errors.password?.message}</FormHelperText>
            </div>
            <div className={style.fld}>
              <div className={style.enterText}>Повторите пароль:</div>
              <TextField
                {...methods.register('password2')}
                id="password2"
                name="password2"
                type="password"
                variant="standard"
                inputProps={{
                  'data-test-id': 'password2',
                }}
                aria-describedby="password-error-text2"
                error={!!methods.formState.errors.password2}
                fullWidth
              />
              <FormHelperText id="password-error-text2">
                {methods.formState.errors.password2?.message}
                {progress === PROGRESS_TYPES.ERROR && 'Ошибка при изменении пароля'}
              </FormHelperText>
            </div>

            <Button.Standart
              dataTestId={'submit-btn'}
              disabled={loginProgress === PROGRESS_TYPES.WORK}
              loading={loginProgress === PROGRESS_TYPES.WORK}
              className={clsx(style.signinButton, style.newPasswordButton)}
            >
              Установить новый пароль
            </Button.Standart>
            <div data-test-id={'restore'} className={style.restoreText}>
              Уже есть логин и пароль?
              <Link className={style.href} to={PATH.SIGNIN} data-test-id={getTestId('link-forgot-password')}>
                Войти
              </Link>
            </div>
          </>
        )}
        {progress === PROGRESS_TYPES.SUCCESS && (
          <>
            <div className={style.restoreText}>Новый пароль установлен!</div>
            <Button.Standart
              dataTestId={'signin-btn'}
              disabled={false}
              className={style.signinButton}
              onClick={() => navigate(PATH.MAIN)}
            >
              Логин
            </Button.Standart>
          </>
        )}
      </form>
      <div className={style.description}>Сервис по мониторингу объектов строительства</div>
      <Footer />
    </div>
  )
}

export default NewPassword
