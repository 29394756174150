/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedReportList } from '../models/PaginatedReportList'
import type { Report } from '../models/Report'
import type { ReportAnnotations } from '../models/ReportAnnotations'
import type { ReportAnnotationsRequest } from '../models/ReportAnnotationsRequest'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class MainReportService {
  /**
   * @param ordering Which field to use when ordering the results.
   * @param page A page number within the paginated result set.
   * @param pageSize Number of results to return per page.
   * @param projectId
   * @returns PaginatedReportList
   * @throws ApiError
   */
  public static mainReportList(
    ordering?: Array<'id' | '-id' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at'>,
    page?: number,
    pageSize?: number,
    projectId?: Array<number>
  ): CancelablePromise<PaginatedReportList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/report/',
      query: {
        ordering: ordering,
        page: page,
        page_size: pageSize,
        project_id: projectId,
      },
    })
  }

  /**
   * @param requestBody
   * @returns ReportAnnotations
   * @throws ApiError
   */
  public static mainReportAnnotationsCreate(
    requestBody: ReportAnnotationsRequest
  ): CancelablePromise<ReportAnnotations> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/main/report-annotations/',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id A unique integer value identifying this отчёт.
   * @returns Report
   * @throws ApiError
   */
  public static mainReportRetrieve(id: number): CancelablePromise<Report> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/main/report/{id}/',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id A unique integer value identifying this отчёт.
   * @returns void
   * @throws ApiError
   */
  public static mainReportDestroy(id: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/main/report/{id}/',
      path: {
        id: id,
      },
    })
  }
}
