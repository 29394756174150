import { useCallback, useEffect, useMemo } from 'react'
import { CircleLayer, Layer, LineLayer, MapLayerMouseEvent, Source } from 'react-map-gl'
import { ITempPoint } from '../RouteMarking'
import { IPoint, IPoints, ITrajectory } from 'utils/interfaces'

const RoutePointsOnMap = ({
  map,
  points,
  selectedPoint,
  onPointClicked,
}: {
  map?: mapboxgl.Map
  points: Array<ITempPoint>
  selectedPoint?: ITempPoint
  onPointClicked: (e: MapLayerMouseEvent) => void
}) => {
  const routeStyle = useMemo<LineLayer>(() => {
    return {
      id: 'routeline-style',
      type: 'line',
      layout: {
        'line-cap': 'square',
      },
      paint: {
        'line-width': 2,
        'line-color': '#4E78F6',
        'line-opacity': 1,
      },
    }
  }, [])

  const pointsStyle = useMemo<CircleLayer>(() => {
    return {
      id: 'points-style',
      type: 'circle',
      paint: {
        'circle-color': '#4E78F6',
        'circle-radius': 5,
      },
    }
  }, [])

  const pointsHaloStyle = useMemo<CircleLayer>(() => {
    return {
      id: 'points-halo-style',
      type: 'circle',
      paint: {
        'circle-color': 'white',
        'circle-radius': 9,
      },
    }
  }, [])

  const selectedPointStyle = useMemo<CircleLayer>(() => {
    return {
      id: 'selected-point-style',
      type: 'circle',
      paint: {
        'circle-color': '#3AB084',
        'circle-radius': 5,
      },
    }
  }, [])

  const lineFeature = useMemo<ITrajectory>(() => {
    return {
      type: 'Feature',
      geometry: {
        coordinates: points.map(point => [point.long, point.lat]) || [],
        type: 'LineString',
      },
      properties: {},
    }
  }, [points])

  const pointsFeature = useMemo<IPoints>(() => {
    return {
      type: 'FeatureCollection',
      features: points.map(point => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [point.long, point.lat],
        },
        properties: {},
      })),
    }
  }, [points])

  const selectedPointFeature = useMemo<IPoint>(() => {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: selectedPoint ? [selectedPoint.long, selectedPoint.lat] : [],
      },
      properties: {
        id_on_map: selectedPoint ? selectedPoint?.frameNumber.toString() : '',
      },
    }
  }, [selectedPoint])

  const onMouseEnter = useCallback(
    (e: mapboxgl.MapMouseEvent) => {
      if (!map) {
        return
      }
      map.getCanvas().style.cursor = 'pointer'
    },
    [map]
  )

  const onMouseLeave = useCallback(() => {
    if (!map) {
      return
    }
    map.getCanvas().style.cursor = 'default'
  }, [map])

  useEffect(() => {
    if (!map) {
      return
    }
    map.on('mouseenter', 'points-halo-style', onMouseEnter)
    map.on('mouseleave', 'points-halo-style', onMouseLeave)
    map.on('click', 'points-halo-style', onPointClicked)
    return () => {
      map.off('mouseenter', 'points-halo-style', onMouseEnter)
      map.off('mouseleave', 'points-halo-style', onMouseLeave)
      map.off('click', 'points-halo-style', onPointClicked)
    }
  }, [map, onMouseEnter, onMouseLeave, onPointClicked])

  return (
    <>
      <Source id="points-line" type="geojson" data={lineFeature}>
        <Layer {...routeStyle} />
      </Source>
      <Source id="points" type="geojson" data={pointsFeature}>
        <Layer {...pointsHaloStyle} />
        <Layer {...pointsStyle} />
      </Source>
      <Source id="selectedPoint" type="geojson" data={selectedPointFeature}>
        <Layer {...selectedPointStyle} />
      </Source>
    </>
  )
}

export default RoutePointsOnMap
