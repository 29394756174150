import { useState } from 'react'
import logo from 'images/logo.png'
import Modal from 'react-modal'
import style from '../Signin.module.css'

const Footer = () => {
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <div className={style.footerLinks}>
        {/* <div className={style.footerLink}>Договор-оферта</div> */}
        <a href={'https://avtodor-eng.ru/policy'} target="_blank" rel="noreferrer" className={style.policyLink}>
          Политика конфиденциальности
        </a>
        {/* <div className={style.footerLink}>Безопасность</div> */}
        <div className={style.footerLink} onClick={() => setShowModal(true)}>
          Помощь
        </div>
      </div>
      <a href="https://avtodor-eng.ru/" target="_blank" rel="noreferrer" className={style.footerText}>
        © ООО “Автодор-Инжиниринг” 2024
      </a>
      <Modal
        ariaHideApp={false}
        closeTimeoutMS={200}
        className={style.modal}
        overlayClassName={style.overlay}
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
      >
        <img src={logo} alt="logo" className={style.logoModal} />
        <p>
          При возникновении вопросов
          <br /> свяжитесь с нами!{' '}
        </p>
        <p>
          Тел. <a href="tel:+74957759920">+7 (495) 775-99-20</a>
        </p>
        <p>
          <a href="mailto:Aeroinspector@avtodor-eng.ru">Aeroinspector@avtodor-eng.ru</a>
        </p>
      </Modal>
    </>
  )
}

export default Footer
