export enum PROGRESS_TYPES {
  IDLE = 'idle',
  WORK = 'work',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum LOGIN_ERROR_TYPES {
  NONE,
  INVALID_EMAIL_OR_PASSWORD,
  ERROR,
}

export enum PATH {
  SIGNIN = '/signin',
  MAIN = '/',
  MAIN_PROJECT = '/:projectId',
  VIDEO = '/:projectId/video/:id',
  ROUTE_MARKING = '/route-marking',
  PRINT_REPORT = '/report',
  RESTORE_PASSWORD = '/restore-password',
  NEW_PASSWORD = '/new-password',
  EMPTY = '',
  OTHERS = '*',
  NOT_FOUND = '/404',
}

export const TOKEN_MAPBOX = 'pk.eyJ1Ijoid2luc2VudCIsImEiOiJjbDdzdDJjeGEwNmI3M3BvMHVtazRzbDNvIn0.Vt0DpZ-ngznh3CiGNJYViQ'

export const enum STYLES {
  VECTOR = 'https://api.maptiler.com/maps/1e4114e3-dd5e-41b0-bf3b-e5d85508135d/style.json?key=vAl8JBKUmrACTOr76jI6',
  SATELLITE = 'https://api.maptiler.com/maps/ea451abd-6300-492a-8eaa-20b4e40e31e8/style.json?key=vAl8JBKUmrACTOr76jI6',
}
