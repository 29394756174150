import { FeatureCollection, Point } from 'geojson'
import { ProjectMark } from 'api/schema'
import { useMemo } from 'react'
import { CircleLayer, Layer, Source, SymbolLayer } from 'react-map-gl'

const Raster = ({ pickets }: { pickets: Array<ProjectMark> }) => {
  const picketsStyle = useMemo<CircleLayer>(() => {
    return {
      id: 'pickets-style',
      type: 'circle',
      paint: {
        'circle-radius': 2,
        'circle-color': 'blue',
      },
    }
  }, [])

  const textStyle: SymbolLayer = {
    id: 'text-layer',
    type: 'symbol',
    layout: {
      'text-field': ['get', 'id'],
      'text-font': ['Open Sans Regular'],
      'text-offset': [0, 1],
      'text-anchor': 'center',
    },
    paint: {
      'text-halo-color': 'white',
      'text-halo-width': 2,
      'text-halo-blur': 1,
    },
  }

  const picketsSource: FeatureCollection<Point> = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: pickets.map(picket => {
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: picket.point.coordinates || [],
          },
          properties: {
            id: picket.number,
          },
        }
      }),
    }
  }, [pickets])

  return (
    <Source id="pickets" type="geojson" data={picketsSource}>
      <Layer {...picketsStyle} />
      <Layer {...textStyle} />
    </Source>
  )
}

export default Raster
