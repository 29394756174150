import VideoPlayer from 'components/VIdeoPlayer/VideoPlayer'
import MapboxCommon from 'components/MapboxMap/MapboxCommon'
import store from 'store/store'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { allProjectsThunks } from 'store/thunks/allProjects'
import { allVideosThunks } from 'store/thunks/allVideos'
import InfoBlock from './InfoBlock/InfoBlock'
import style from './RouteMarking.module.css'
import { IMapboxElement, IPoint, ITrajectory, RootState } from 'utils/interfaces'
import { useSelector } from 'react-redux'
import CenterLineOnMap from './MapLayers/CenterlineOnMap'
import { zoomMapTo } from 'utils/mapUtils'
import { VideoActions } from 'store/actions/video'
import RoutePointsOnMap from './MapLayers/RoutePointsOnMap'
import { MapLayerMouseEvent } from 'mapbox-gl'
import nearestPointOnLine from '@turf/nearest-point-on-line'
import Raster from 'components/MapLayers/Raster'

export interface ITempPoint {
  frameNumber: number
  lat: number
  long: number
}

const RouteMarking = () => {
  const currentProjectDetailed = useSelector((state: RootState) => state.project.project)
  const currentVideo = useSelector((state: RootState) => state.video.video)
  const frameNumber = useSelector((state: RootState) => state.video.frameNumber)
  const mapRef = useRef<IMapboxElement>(null)
  const [points, setPoints] = useState<Array<ITempPoint>>([])
  const [selectedPoint, setSelectedPoint] = useState<ITempPoint | undefined>()

  useEffect(() => {
    store.dispatch(allProjectsThunks.fetchAllProjects())
    store.dispatch(allVideosThunks.fetchAllVideos())
  }, [])

  const centerLine = useMemo<ITrajectory>(() => {
    return {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: currentProjectDetailed?.central_line?.coordinates || [[0, 0]],
      },
      properties: {
        id_on_map: 'centerLine',
      },
    }
  }, [currentProjectDetailed])

  useEffect(() => {
    setPoints([])
    if (currentProjectDetailed?.central_line) {
      zoomMapTo(mapRef.current?.getMap(), [centerLine])
    }
  }, [centerLine, currentProjectDetailed])

  const onTimeUpdate = useCallback((time: number) => {
    const frameNumber = Math.floor(time)
    store.dispatch(VideoActions.setFrameNumber(frameNumber))
  }, [])

  const onDeletePoint = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    e.stopPropagation()
    setPoints(points.filter((_, i) => i !== index))
  }

  const onPointClicked = (point: ITempPoint) => {
    setSelectedPoint(point)
    window.dispatchEvent(new CustomEvent('videoSeekFrameEvent', { detail: point.frameNumber }))
  }

  const onMapPointClicked = (e: MapLayerMouseEvent) => {
    e.originalEvent.stopPropagation()
    e.preventDefault()
  }

  const onRouteClicked = useCallback(
    (coords: mapboxgl.LngLat) => {
      const nearestPoint = nearestPointOnLine(centerLine, [coords.lng, coords.lat]) as IPoint
      const newPoints = [
        ...points,
        { frameNumber, lat: nearestPoint.geometry.coordinates[1], long: nearestPoint.geometry.coordinates[0] },
      ]
      newPoints.sort((a, b) => a.frameNumber - b.frameNumber)
      setPoints(newPoints)
    },
    [centerLine, frameNumber, points]
  )

  return (
    <div className={style.columns}>
      <div className={style.leftColumn}>
        <div className={style.video}>
          <VideoPlayer video={currentVideo} onTimeUpdate={onTimeUpdate} />
        </div>
        <div className={style.map}>
          <MapboxCommon ref={mapRef}>
            <Raster url={currentProjectDetailed?.layer_top || ''} id="raster-1" beforeId="centerline-style" />
            <Raster url={currentProjectDetailed?.layer_bottom || ''} id="raster-2" beforeId="centerline-style" />
            <CenterLineOnMap centerLine={centerLine} onRouteClicked={onRouteClicked} map={mapRef.current?.getMap()} />
            <RoutePointsOnMap
              points={points}
              onPointClicked={onMapPointClicked}
              selectedPoint={selectedPoint}
              map={mapRef.current?.getMap()}
            />
          </MapboxCommon>
        </div>
      </div>
      <div className={style.rightColumn}>
        <InfoBlock
          points={points}
          selectedPoint={selectedPoint}
          onDeletePoint={onDeletePoint}
          onPointClicked={onPointClicked}
        />
      </div>
    </div>
  )
}

export default RouteMarking
