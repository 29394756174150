import { useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import TaskItem from './TaskItem/TaskItem'
import Loader from 'components/Loader/Loader'
import EditTask from './EditTask/EditTask'
import { ReactComponent as AddAnnotationSvg } from 'images/icons/addAnnotation.svg'
import dateFnsFormat from 'date-fns/format'
import { ITaskFilters, RootState } from 'utils/interfaces'
import { PROGRESS_TYPES } from 'utils/constants'
import { MainProjectAnnotationService, VideoAnnotationReadInstance } from 'api/schema'
import { Button } from 'components/Button/Button'
import TaskFilter from './TaskFilter/TaskFilter'
import { useUser } from 'hooks/user'
import ru from 'date-fns/locale/ru'
import style from './Tasks.module.css'

const Tasks = ({ show }: { show: boolean }) => {
  const user = useUser()
  const [newTask, setNewTask] = useState(false)
  const frameNumber = useSelector((state: RootState) => state.video.frameNumber)
  const frame = useSelector((state: RootState) => state.video.frames[frameNumber] || state.video.frames[0])
  const video = useSelector((state: RootState) => state.video.video)
  const project = useSelector((state: RootState) => {
    return state.project.project
  })
  const [loading, setLoading] = useState(PROGRESS_TYPES.IDLE)
  const [tasks, setTasks] = useState<VideoAnnotationReadInstance[]>([])
  const [filters, setFilters] = useState<ITaskFilters>({
    status: 'active',
    user: 'all',
    video: 'current',
    date: 'all',
  })

  const fetchTasks = useCallback(
    async (filters: ITaskFilters) => {
      try {
        if (!project) {
          return
        }
        setLoading(PROGRESS_TYPES.WORK)
        const completedParam = filters.status === 'completed' ? true : filters.status === 'active' ? false : undefined
        const userParam = filters.user === 'me' && user?.state.id ? [user?.state.id.toString()] : undefined
        const videoIdParam = filters.video === 'current' && video?.id ? [video?.id] : undefined
        const outdatedParam = filters.date === 'outdated' ? true : filters.date === 'inTime' ? false : undefined
        const annotationsRaw = await MainProjectAnnotationService.mainVideoAnnotationList(
          undefined,
          undefined,
          undefined,
          outdatedParam,
          completedParam,
          ['-created_at'],
          undefined,
          [project.id],
          userParam,
          videoIdParam
        )

        setTasks(annotationsRaw)
        setLoading(PROGRESS_TYPES.SUCCESS)
      } catch (error) {
        setLoading(PROGRESS_TYPES.ERROR)
      }
    },
    [project, user?.state.id, video?.id]
  )

  useEffect(() => {
    if (!project?.id) {
      return
    }
    const savedFilters = localStorage.getItem(`filters-${project.id}`)
    if (savedFilters) {
      const savedFiltersParsed = JSON.parse(savedFilters)
      setFilters(savedFiltersParsed)
      fetchTasks(savedFiltersParsed)
    } else {
      fetchTasks(filters)
    }
  }, [project?.id, fetchTasks])

  const onFiltersChanged = (newFilters: ITaskFilters) => {
    if (!project?.id) {
      return
    }
    setFilters(newFilters)
    localStorage.setItem(`filters-${project?.id}`, JSON.stringify(newFilters))
    fetchTasks(newFilters)
  }

  const onNewTaskSave = useCallback(
    async (text: string, executorId?: number, executionDate?: string | null, factDate?: string | null) => {
      try {
        if (!video || !project) {
          return
        }
        await MainProjectAnnotationService.mainVideoAnnotationCreate({
          project_id: project.id,
          point: frame.point_display,
          first_appeared_at: video.date ? dateFnsFormat(new Date(video.date), 'yyyy-MM-dd', { locale: ru }): '',
          scheduled_to_resolve_at: executionDate?.toString(),
          resolved_at: factDate?.toString(),
          text,
          assignee_to_id: executorId,
        })
        fetchTasks(filters)
        setNewTask(false)
      } catch (error) {
        console.error(error)
      }
    },
    [fetchTasks, filters, frame.point_display, project, video]
  )

  const onTaskEdited = useCallback(() => {
    fetchTasks(filters)
  }, [fetchTasks, filters])

  return show ? (
    <div className={style.tasksBlock}>
      <div className={style.filterRow}>
        <TaskFilter filters={filters} onFiltersChanged={onFiltersChanged} />
        {project?.current_user_data.can_add_video_annotation && (
          <Button.Standart
            className={style.iconButton}
            onClick={() => {
              setNewTask(true)
            }}
          >
            <AddAnnotationSvg />
          </Button.Standart>
        )}
      </div>
      <div className={style.tasksList}>
        {newTask && (
          <EditTask
            time={frameNumber}
            disabled={loading === PROGRESS_TYPES.WORK}
            onCancel={() => {
              setNewTask(false)
            }}
            onSave={onNewTaskSave}
          />
        )}
        {loading === PROGRESS_TYPES.WORK && tasks.length === 0 && <Loader />}
        {loading === PROGRESS_TYPES.SUCCESS && tasks.length === 0 && !newTask && (
          <div className={style.emptyMessage}>Задач пока нет</div>
        )}
        {(loading === PROGRESS_TYPES.SUCCESS || loading === PROGRESS_TYPES.WORK) &&
          tasks.length > 0 &&
          tasks.map(task => <TaskItem key={task.id} annotation={task} onEdited={onTaskEdited} />)}
      </div>
    </div>
  ) : null
}

export default Tasks
