import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { MainProjectService } from 'api/schema'
import { AllProjectsActions } from 'store/actions/allProjects'
import { PROGRESS_TYPES } from 'utils/constants'
import { RootState } from 'utils/interfaces'

const fetchAllProjects = () => {
  return async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    try {
      dispatch(AllProjectsActions.setLoadingProgress(PROGRESS_TYPES.WORK))
      const allProjectsRaw = await MainProjectService.mainProjectList()
      dispatch(AllProjectsActions.setAllProjects(allProjectsRaw.results || []))
      dispatch(AllProjectsActions.setLoadingProgress(PROGRESS_TYPES.SUCCESS))
    } catch (error) {
      dispatch(AllProjectsActions.setLoadingProgress(PROGRESS_TYPES.ERROR))
    }
  }
}

export const allProjectsThunks = {
  fetchAllProjects,
}
