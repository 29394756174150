import { useMemo } from 'react'
import { Layer, LineLayer, Source } from 'react-map-gl'
import { ITrajectory } from 'utils/interfaces'

const CenterLineOnMap = ({ centerLine }: { centerLine: ITrajectory }) => {
  const transferRouteHaloStyle = useMemo<LineLayer>(() => {
    return {
      id: 'centerline-style',
      type: 'line',
      layout: {
        'line-cap': 'square',
      },
      paint: {
        'line-width': 12,
        'line-color': '#D1D5A9',
        'line-opacity': 1,
      },
    }
  }, [])

  const transferRouteStyle = useMemo<LineLayer>(() => {
    return {
      id: 'centerline-halo-style',
      type: 'line',
      layout: {
        'line-cap': 'square',
      },
      paint: {
        'line-width': 8,
        'line-color': '#FDE29D',
        'line-opacity': 1,
      },
    }
  }, [])

  return (
    <Source id="centerline" type="geojson" data={centerLine}>
      <Layer {...transferRouteHaloStyle} />
      <Layer {...transferRouteStyle} />
    </Source>
  )
}

export default CenterLineOnMap
