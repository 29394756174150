import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { FormControlLabel, TextField } from '@mui/material'
import { Button } from 'components/Button/Button'
import ProgressLine from 'components/ProgressLine/ProgressLine'
import AntSwitch from 'components/AntSwitch/AntSwitch'
import Dropdown, { IOption } from 'components/Dropdown/Dropdown'
import toTimestamp from 'utils/secondsToTimestamp'
import getUserName from 'utils/getUserName'
import { RootState } from 'utils/interfaces'
import { ProjectUser, UserInline } from 'api/schema'
import dateFnsFormat from 'date-fns/format'
import { ReactComponent as TimeSvg } from 'images/icons/time.svg'
import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker'

import style from './EditTask.module.css'

type EditTaskProps = {
  time: number
  oldTime?: string
  text?: string
  executorId?: number
  dateExecution?: string | null
  dateFact?: string | null
  complete?: boolean
  disabled: boolean
  onCancel: () => void
  onSave: (text: string, executorId?: number, executionDate?: string | null, factDate?: string | null) => void
  onDelete?: (e: any) => Promise<void>
  onOldTimeClicked?: () => void
}

const EditTask = ({
  time,
  oldTime,
  text,
  executorId,
  dateExecution,
  dateFact,
  complete,
  disabled,
  onCancel,
  onSave,
  onDelete,
  onOldTimeClicked,
}: EditTaskProps) => {
  const valueRef = useRef<HTMLInputElement>()
  const [taskDesc, setTaskDesc] = useState<string>(text || '')
  const [loading, setLoading] = useState(!!onDelete)
  const frameNumber = useSelector((state: RootState) => state.video.frameNumber)
  const picketText = useSelector((state: RootState) => {
    const picketNumber = state.video.frames[frameNumber]?.mark_number
    return picketNumber ? 'ПК' + picketNumber : ''
  })
  const project = useSelector((state: RootState) => state.project.project)
  const [executor, setExecutor] = useState<ProjectUser | UserInline | undefined>(
    project?.users.find(user => user.id === executorId)
  )
  const [dateExecutionState, setDateExecutionState] = useState(dateExecution ? new Date(dateExecution) : null)
  const [dateFactState, setDateFactState] = useState<Date | null>(dateFact ? new Date(dateFact) : null)
  const [completeState, setCompleteState] = useState(complete ? complete : false)
  const [userFilter, setUserFilter] = useState<string>('')

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 3000)
  }, [])

  useEffect(() => {
    if (oldTime === toTimestamp(time)) {
      setLoading(false)
    }
  }, [oldTime, time])

  const onCancelWrapped: React.MouseEventHandler<HTMLButtonElement> = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation()
    onCancel()
  }

  return (
    <div
      className={style.annotation}
      onClick={e => {
        e.stopPropagation()
      }}
    >
      <div>
        <TextField
          id="description"
          name="description"
          type="text"
          variant="outlined"
          autoFocus={true}
          multiline
          sx={{
            '& .MuiOutlinedInput-root ': {
              padding: '0',
            },
            '& .MuiInputBase-inputMultiline': {
              color: 'white',
              fontSize: '11px',
              fontStyle: 'italic',
              borderRadius: '2px',
              background: '#777777',
              padding: '5px',
              height: '32px',
            },
            '& .MuiOutlinedInput-root': {
              '& > fieldset': {
                border: 'none',
                padding: '0',
              },
            },
          }}
          placeholder={'Добавьте описание...'}
          inputProps={{
            'data-test-id': 'description',
          }}
          fullWidth
          inputRef={valueRef}
          defaultValue={taskDesc}
          onKeyDown={e => {
            e.stopPropagation()
          }}
          onChange={e => {
            setTaskDesc(e.target.value)
          }}
        />
      </div>
      <div className={style.time}>
        {loading ? (
          <ProgressLine />
        ) : (
          <>
            <TimeSvg className={style.timeMargin} />
            {oldTime && oldTime !== toTimestamp(time) && (
              <span className={clsx(style.timeCrossedOut, style.timeMargin)} onClick={onOldTimeClicked}>
                {oldTime}
              </span>
            )}{' '}
            <span className={style.timeMargin}>{toTimestamp(time)}</span>
            <span className={style.timeMargin}>{picketText}</span>
          </>
        )}
      </div>
      <div className={style.row}>
        <div className={style.column}>
          {project && (
            <Dropdown
              placeholderClassName={style.placeholder}
              listClassName={style.list}
              placeholder="Выбрать исполнителя"
              options={project?.users
                .map(user => ({ value: user.id, text: getUserName(user) }))
                .filter(option => option.text.toLowerCase().includes(userFilter.toLowerCase()))}
              currentOption={executor ? { value: executor.id, text: getUserName(executor) } : undefined}
              onClickOption={(e: IOption) => {
                setExecutor(project?.users.find(user => user.id === e.value))
                setUserFilter('')
              }}
            >
              <TextField
                id="userFilter"
                name="userFilter"
                type="text"
                variant="outlined"
                autoFocus={true}
                multiline
                sx={{
                  '& .MuiOutlinedInput-root ': {
                    padding: '10px',
                  },
                  '& .MuiInputBase-inputMultiline': {
                    color: 'white',
                    fontSize: '11px',
                    borderRadius: '2px',
                    background: '#777777',
                    padding: '3px',
                    height: '23px',
                    width: '92%',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& > fieldset': {
                      border: 'none',
                      padding: '0',
                    },
                  },
                }}
                placeholder={'Поиск по исполнителю'}
                inputProps={{
                  'data-test-id': 'filter',
                }}
                fullWidth
                inputRef={valueRef}
                defaultValue={userFilter}
                onKeyDown={e => {
                  e.stopPropagation()
                }}
                onChange={e => {
                  setUserFilter(e.target.value)
                }}
              />
            </Dropdown>
          )}
          <FormControlLabel
            className={style.switch}
            control={
              <AntSwitch
                checked={completeState}
                onChange={(e, value) => {
                  setCompleteState(value)
                  if (value) {
                    setDateFactState(new Date())
                  }
                }}
              />
            }
            label="Задача завершена"
            disableTypography={true}
          />
        </div>
        <div className={style.column}>
          <CustomDatePicker
            date={dateExecutionState}
            onChange={(date: Date | null) => setDateExecutionState(date)}
            placeholderText="Срок исполнения"
            disabled={false}
          />
          <CustomDatePicker
            date={completeState ? dateFactState : null}
            onChange={(date: Date | null) => {
              setDateFactState(date)
              console.log(date)
            }}
            placeholderText="Дата завершения"
            disabled={!completeState}
          />
        </div>
      </div>
      <div className={style.buttonsBlock}>
        {onDelete && (
          <Button.Standart className={clsx(style.button, style.buttonDelete)} onClick={onDelete} disabled={disabled}>
            Удалить
          </Button.Standart>
        )}
        <Button.Standart className={clsx(style.button, style.buttonCancel)} onClick={onCancelWrapped}>
          Отмена
        </Button.Standart>
        <Button.Standart
          className={clsx(style.button, style.buttonSave)}
          disabled={loading || !taskDesc || disabled}
          onClick={() =>
            onSave(
              taskDesc,
              executor?.id,
              dateExecutionState ? dateFnsFormat(dateExecutionState, 'yyyy-MM-dd') : null,
              completeState && dateFactState ? dateFnsFormat(dateFactState, 'yyyy-MM-dd') : null
            )
          }
        >
          Сохранить
        </Button.Standart>
      </div>
    </div>
  )
}

export default EditTask
