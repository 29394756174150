import * as yup from 'yup'
import yupPassword from 'yup-password'

yupPassword(yup)

export interface ITextRequired {
  messageRequired?: string
}

export interface IEmailSchema extends ITextRequired {
  messageEmail?: string
}

export const emailSchema = (
  payload: IEmailSchema = {
    messageEmail: 'signin.emailMessageValidError',
    messageRequired: 'signin.emailRequiredError',
  }
) => yup.string().email(payload.messageEmail).required(payload.messageRequired)

export const passwordSchema = (payload: ITextRequired = { messageRequired: 'signin.passwordRequiredError' }) =>
  yup.string().required(payload.messageRequired)

export const newPasswordSchema = () =>
  yup.string().required('Пароль не может быть пустым').min(8, 'Пароль должен быть не менее 8 символов')
// .minLowercase(1, 'Password must contain at least 1 lower case letter')
// .minUppercase(1, 'Password must contain at least 1 upper case letter')
// .minNumbers(1, 'Password must contain at least 1 number')
// .minSymbols(1, 'Password must contain at least 1 special character')

export const confirmPasswordSchema = () => yup.string().oneOf([yup.ref('password')], 'Пароли должны совпадать')

export const urlSchema = () => yup.string().url('Неверный формат ссылки').required('Ссылка не может быть пустой')
