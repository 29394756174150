import LinearProgress from '@mui/material/LinearProgress'
import { ThemeProvider, createTheme } from '@mui/material/styles'

import styles from './ProgressLine.module.css'

const theme = createTheme({
  palette: {
    primary: {
      main: '#4E78F6',
    },
  },
})

function ProgressLine() {
  return (
    <div className={styles.root}>
      <ThemeProvider theme={theme}>
        <LinearProgress color="primary" sx={{ width: '100%' }} />
      </ThemeProvider>
    </div>
  )
}

export default ProgressLine
