import { VideoAnnotationReadInstance } from 'api/schema'
import styles from './ReportPreview.module.css'
import { IReportFilters } from 'utils/interfaces'
import dateFnsFormat from 'date-fns/format'
import ru from 'date-fns/locale/ru'
import getUserName from 'utils/getUserName'
import { groupBy } from 'lodash'
import clsx from 'clsx'
import { isOverdue } from 'utils/isTaskOverdue'
import CarouselImages from './CarouselImages/CarouselImages'
import logoOneSrc from 'images/new/logo1.png'

const ReportPreviewLayout = ({
  filters,
  tasks,
  author,
  projectName,
  selectedPhotos,
  onSelectedPhotosChange,
}: {
  filters: IReportFilters
  tasks: VideoAnnotationReadInstance[]
  author: string
  projectName: string
  selectedPhotos: Record<number, number>
  onSelectedPhotosChange?: (selectedPhotosNew: Record<number, number>) => void
}) => {
  const formatDateStandart = (date: Date) => {
    return dateFnsFormat(date, 'dd.MM.yyyy', { locale: ru })
  }
  const tasksDividedByExecutor = groupBy(tasks, obj => (obj.assignee_to_id ? obj.assignee_to_id : 'undefined'))
  const formatDate = (date: string) => {
    return dateFnsFormat(new Date(date), 'dd.MM.yyyy', { locale: ru })
  }
  const getImageSrc = (task: VideoAnnotationReadInstance) => {
    return task.frames[task.frames.length - 1]?.frame?.thumbnail
  }
  const onCurrentPhotoIndexChange = (taskId: number, photoIndex: number) => {
    if (onSelectedPhotosChange) {
      const newSelectedPhotos = { ...selectedPhotos }
      newSelectedPhotos[taskId] = photoIndex
      onSelectedPhotosChange(newSelectedPhotos)
    }
  }

  return (
    <div className={styles.root}>
      <img className={styles.logoImg} src={logoOneSrc} alt="logo" />
      <div className={styles.subtitle}>Сервис по мониторингу объектов строительства</div>
      <div className={styles.subtitle2}>Графическая версия отчета для печати</div>
      <div className={styles.title}>Отчет о ходе строительства скоростной автомобильной дороги</div>
      <div className={styles.title}>{projectName}</div>

      <div className={styles.createdDate}>
        {(filters.dateStart || filters.dateEnd) && (
          <div className={styles.period}>
            Даты формирования задач:
            {filters.dateStart && <span>{' ' + formatDateStandart(filters.dateStart)}</span>}
            {filters.dateStart && filters.dateEnd && <span> - </span>}
            {filters.dateEnd && <span>{formatDateStandart(filters.dateEnd)} </span>}
          </div>
        )}
        <div className={styles.period}>Дата формирования отчета: {formatDateStandart(new Date())}</div>
      </div>
      {tasks.length === 0 && <div className={styles.noTasks}>Задач по заданным фильтрам нет</div>}
      {Object.keys(tasksDividedByExecutor).map((executorId, index) => {
        const tasksForExecutor = tasksDividedByExecutor[executorId]
        const executorName = tasksForExecutor[0].assignee_to
          ? getUserName(tasksForExecutor[0].assignee_to)
          : 'Без исполнителя'
        return (
          <div key={executorId || 999} className={styles.taskExecutor}>
            <div className={styles.taskGroupTitle}>
              {index + 1}. {executorName}:
            </div>
            <table className={styles.table}>
              <thead className={styles.tableHeader}>
                <tr className={clsx(styles.tableTitle)}>
                  <th className={clsx(styles.tableCell, styles.col1)}>№п/п</th>
                  <th className={clsx(styles.tableCell, styles.col2)}>Видео</th>
                  <th className={clsx(styles.tableCell, styles.col3)}>Описание</th>
                  <th className={clsx(styles.tableCell, styles.col3)}>ПК</th>
                  <th className={clsx(styles.tableCell, styles.col4)}>Срок исполнения</th>
                  <th className={clsx(styles.tableCell, styles.col5)}>Статус</th>
                </tr>
              </thead>
              <tbody className={styles.tableBody}>
                {tasksForExecutor.map((task: VideoAnnotationReadInstance, index2) => (
                  <tr key={index2 + '.' + task.id}>
                    <td className={clsx(styles.tableCell, styles.col1)}>
                      {index + 1}.{index2 + 1}
                    </td>
                    <td className={clsx(styles.tableCell, styles.col2)}>
                      {getImageSrc(task) ? (
                        <img src={getImageSrc(task) || ''} alt="Video preview" width="200" height="150" />
                      ) : (
                        'Нет изображения'
                      )}
                    </td>
                    <td className={clsx(styles.tableCell, styles.col3)}>{task.text}</td>
                    <td className={clsx(styles.tableCell, styles.col3)}>
                      {task.frames[0].frame.mark_number ? 'ПК' + task.frames[0].frame.mark_number : '-'}
                    </td>
                    <td className={clsx(styles.tableCell, styles.col4)}>
                      {task.scheduled_to_resolve_at && <span>{formatDate(task.scheduled_to_resolve_at)}</span>}
                      {!task.scheduled_to_resolve_at && <span>Без срока</span>}
                      {isOverdue(task) && <div> (просрочено)</div>}
                    </td>
                    <td className={clsx(styles.tableCell, styles.col5)}>
                      {!task.is_resolved && 'Активна'}
                      {task.is_resolved && 'Выполнена'}
                      {task.is_resolved && task.resolved_at && <div>({formatDate(task.resolved_at)})</div>}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )
      })}
      {Object.keys(tasksDividedByExecutor).map((executorId, executorIndex) => {
        const tasksForExecutor = tasksDividedByExecutor[executorId]
        const executorName = tasksForExecutor[0].assignee_to
          ? getUserName(tasksForExecutor[0].assignee_to)
          : 'Без исполнителя'
        return (
          <>
            {tasksForExecutor.map((task, taskIndex) => {
              return (
                <div key={executorId + '.' + task.id + taskIndex} className={styles.taskExecutor}>
                  <CarouselImages
                    imagesUrls={task.frames.map(frame => frame.image)}
                    dateTimes={task.frames.map(frame => frame.video.date)}
                    currentPhotoIndex={selectedPhotos[taskIndex] || 0}
                    onCurrentPhotoIndexChange={(photoIndex: number) => onCurrentPhotoIndexChange(task.id, photoIndex)}
                  />
                  <table className={clsx(styles.table, styles.tableSmall)}>
                    <thead className={styles.tableHeader}>
                      <tr className={clsx(styles.tableRow, styles.taskRow)}>
                        <th className={styles.tableCellRegular}>№</th>
                        <th className={styles.tableCellRegular}>Исполнитель</th>
                        <th className={styles.tableCellRegular}>Дата фиксации</th>
                        <th className={styles.tableCellRegular}>Описание</th>
                        <th className={styles.tableCellRegular}>ПК</th>
                        <th className={styles.tableCellRegular}>Срок исполнения</th>
                        <th className={styles.tableCellRegular}>Статус</th>
                      </tr>
                    </thead>
                    <tbody className={styles.tableBody}>
                      <tr key={task.id} className={clsx(styles.tableRow, styles.taskRow)}>
                        <td className={styles.tableCell}>
                          {executorIndex + 1}.{taskIndex + 1}
                        </td>
                        <td className={styles.tableCell}>{executorName}</td>
                        <td className={styles.tableCell}>{formatDate(task.created_at)}</td>
                        <td className={styles.tableCell}>{task.text}</td>
                        <td className={styles.tableCell}>
                          {task.frames[0].frame.mark_number ? 'ПК' + task.frames[0].frame.mark_number : '-'}
                        </td>
                        <td className={styles.tableCell}>
                          {task.scheduled_to_resolve_at && <span>{formatDate(task.scheduled_to_resolve_at)}</span>}
                          {!task.scheduled_to_resolve_at && <span>Без срока</span>}
                          {isOverdue(task) && <div> (просрочено)</div>}
                        </td>
                        <td className={styles.tableCell}>
                          {!task.is_resolved && 'Активна'}
                          {task.is_resolved && 'Выполнена'}
                          {task.is_resolved && task.resolved_at && <div>({formatDate(task.resolved_at)})</div>}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )
            })}
          </>
        )
      })}
      <div className={styles.divFooter}>
        <div className={styles.divHeader} />
        <div>
          Дата формирования задач: {filters.dateStart && formatDate(filters.dateStart.toString())} -{' '}
          {filters.dateEnd && formatDate(filters.dateEnd.toString())}
        </div>
        <div>
          Отчет сформирован в онлайн-сервисе <span className={styles.link}>aeroinspector.ru</span>
        </div>
        <div>Дата формирования отчета: {formatDate(new Date().toString())}</div>
      </div>
    </div>
  )
}

export default ReportPreviewLayout
