import { MainProjectAnnotationService, MainProjectService, VideoAnnotationReadInstance } from 'api/schema'
import Loader from 'components/Loader/Loader'
import { useCallback, useEffect, useState } from 'react'
import { PROGRESS_TYPES } from 'utils/constants'
import { isOverdue } from 'utils/isTaskOverdue'
import ReportPreviewLayout from './ReportPreviewLayout'
import { IReportFilters } from 'utils/interfaces'
import dateFnsFormat from 'date-fns/format'

import styles from './ReportPreview.module.css'

type ReportPreviewProps = {
  projectId: number
  filters: IReportFilters
  author: string
  selectedPhotos: Record<number, number>
  onSelectedPhotosChange?: (selectedPhotosNew: Record<number, number>) => void
}

const ReportPreview = ({ projectId, filters, author, selectedPhotos, onSelectedPhotosChange }: ReportPreviewProps) => {
  const [tasks, setTasks] = useState<VideoAnnotationReadInstance[]>([])
  const [loading, setLoading] = useState(PROGRESS_TYPES.IDLE)
  const [projectName, setProjectName] = useState<string>('')

  const fetchTasksAndProject = useCallback(async () => {
    try {
      setLoading(PROGRESS_TYPES.WORK)
      const completedParam = filters.status === 'completed' ? true : filters.status === 'active' ? false : undefined
      const outdatedParam = filters.date === 'outdated' ? true : filters.date === 'inTime' ? false : undefined
      const annotationsRaw = await MainProjectAnnotationService.mainVideoAnnotationList(
        filters.dateStart ? dateFnsFormat(filters.dateStart, 'yyyy-MM-dd') : undefined,
        filters.dateEnd ? dateFnsFormat(filters.dateEnd, 'yyyy-MM-dd') : undefined,
        undefined,
        outdatedParam,
        completedParam,
        ['-created_at'],
        undefined,
        [projectId],
        filters.user ? [filters.user.toString()] : undefined,
        undefined
      )
      let filteredAnnotations = annotationsRaw
      if (filters.date === 'inTime') {
        filteredAnnotations = filteredAnnotations.filter(annotation => !isOverdue(annotation))
      } else if (filters.date === 'outdated') {
        filteredAnnotations = filteredAnnotations.filter(annotation => isOverdue(annotation))
      }
      setTasks(filteredAnnotations)
      const project = await MainProjectService.mainProjectRetrieve(projectId)
      setProjectName(project.name)
      setLoading(PROGRESS_TYPES.SUCCESS)
    } catch (error) {
      setLoading(PROGRESS_TYPES.ERROR)
    }
  }, [filters.status, filters.user, filters.date, projectId, filters.dateStart, filters.dateEnd])

  useEffect(() => {
    const selectedPhotosNew = { ...selectedPhotos }
    tasks.forEach(task => {
      if (!selectedPhotosNew[task.id]) {
        selectedPhotosNew[task.id] = 0
      }
    })
    onSelectedPhotosChange && onSelectedPhotosChange(selectedPhotosNew)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSelectedPhotosChange, tasks])

  useEffect(() => {
    fetchTasksAndProject()
  }, [fetchTasksAndProject])

  return (
    <div className={styles.wrapper}>
      {loading === PROGRESS_TYPES.WORK && <Loader />}
      {loading === PROGRESS_TYPES.SUCCESS && (
        <ReportPreviewLayout
          filters={filters}
          tasks={tasks}
          author={author}
          projectName={projectName || ''}
          selectedPhotos={selectedPhotos}
          onSelectedPhotosChange={onSelectedPhotosChange}
        />
      )}
    </div>
  )
}

export default ReportPreview
