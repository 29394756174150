import { MainProjectVideoService, VideoFrameSearch } from 'api/schema'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'utils/interfaces'
import dateFnsFormat from 'date-fns/format'
import ru from 'date-fns/locale/ru'
import { PATH, PROGRESS_TYPES } from 'utils/constants'
import Loader from 'components/Loader/Loader'

import { generatePath, useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { VideoActions } from 'store/actions/video'

import style from './OtherVideosBlock.module.css'

const OtherVideosBlock = ({ show }: { show: boolean }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const frameNumber = useSelector((state: RootState) => state.video.frameNumber)
  const frames = useSelector((state: RootState) => state.video.frames)
  const project = useSelector((state: RootState) => state.project.project)
  const videoId = useSelector((state: RootState) => state.video.video?.id)
  const videoInited = useSelector((state: RootState) => state.video.inited)

  const frame = useMemo(() => {
    return frames[frameNumber]
  }, [frameNumber, frames])

  const [otherVideos, setOtherVideos] = useState<VideoFrameSearch[]>([])
  const [loading, setLoading] = useState<PROGRESS_TYPES>(PROGRESS_TYPES.IDLE)

  const fetchVideosInFrame = useCallback(async () => {
    if (!frame || !frame.point_display.coordinates || !project) {
      return
    }
    try {
      setLoading(PROGRESS_TYPES.WORK)
      // Грязный хак из-за того, что codegen неправильно реализует спецификацию OpenAPI 3.0.3: при style=form и explode=false массив чисел должен в запросе идти через запятую
      const videosRaw = await MainProjectVideoService.mainVideoFrameSearchList([project.id], 5, undefined, [
        `${frame.point_display.coordinates}` as unknown as number,
      ])
      setOtherVideos(videosRaw)
      setLoading(PROGRESS_TYPES.SUCCESS)
    } catch (error) {
      setLoading(PROGRESS_TYPES.ERROR)
    }
  }, [frame, project])

  useEffect(() => {
    setOtherVideos([])
  }, [frameNumber])

  useEffect(() => {
    if (show && !otherVideos.length && videoId && videoInited) {
      fetchVideosInFrame()
    }
  }, [fetchVideosInFrame, otherVideos.length, show, videoId, frameNumber, videoInited])

  const onClick = (video: VideoFrameSearch) => {
    dispatch(VideoActions.setInited(false))
    const newFrameNumber = video.timestamp_seconds
    dispatch(VideoActions.setFrameNumber(newFrameNumber))
    const path =
      generatePath(PATH.VIDEO, { projectId: String(project?.id), id: String(video.video_id) }) + '?t=' + newFrameNumber
    navigate(path)
  }

  return show ? (
    <div className={style.main}>
      {loading === PROGRESS_TYPES.WORK && <Loader />}
      {loading === PROGRESS_TYPES.SUCCESS && (
        <div className={style.horList}>
          {otherVideos.map(video => (
            <div
              className={video.video_id === videoId ? clsx(style.video, style.activeVideo) : style.video}
              key={video.video_id}
              onClick={() => onClick(video)}
            >
              {video.thumbnail && <img src={video.thumbnail} alt="Thumb" />}
              {video.video.date && (
                <span className={style.text}>
                  {dateFnsFormat(new Date(video.video.date), 'dd LLLL yyyy', { locale: ru })}
                </span>
              )}
            </div>
          ))}
        </div>
      )}
      {loading === PROGRESS_TYPES.ERROR && <div>Ошибка загрузки кадров</div>}
    </div>
  ) : null
}

export default OtherVideosBlock
