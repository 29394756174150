import { useMemo } from 'react'
import { Layer, CircleLayer, Source } from 'react-map-gl'
import { IPoint } from 'utils/interfaces'

const PointOnMap = ({ centerPoint }: { centerPoint?: IPoint }) => {
  const pointCenterStyle = useMemo<CircleLayer>(() => {
    return {
      id: 'point-halo-style',
      type: 'circle',
      paint: {
        'circle-radius': 5,
        'circle-color': '#717FDF',
      },
    }
  }, [])
  const pointHaloStyle = useMemo<CircleLayer>(() => {
    return {
      id: 'point-style',
      type: 'circle',
      paint: {
        'circle-radius': 7,
        'circle-color': 'white',
      },
    }
  }, [])

  return centerPoint ? (
    <Source id="point" type="geojson" data={centerPoint}>
      <Layer {...pointHaloStyle} />
      <Layer {...pointCenterStyle} />
    </Source>
  ) : null
}

export default PointOnMap
