import { createSlice } from '@reduxjs/toolkit'
import { Video } from 'api/schema'
import { PROGRESS_TYPES } from 'utils/constants'
import { IAllVideosState } from 'utils/interfaces'

const initialState: IAllVideosState = {
  loadingProgress: PROGRESS_TYPES.IDLE,
  videos: [],
  groupVideos: [],
}

export const allVideosSlice = createSlice({
  name: 'allVideos',
  initialState,
  reducers: {
    setLoadingProgress: (state: IAllVideosState, action: { payload: PROGRESS_TYPES }) => {
      state.loadingProgress = action.payload
    },
    setAllVideos: (state: IAllVideosState, action: { payload: Video[] }) => {
      state.videos = action.payload
    },
    setGroupVideos: (state: IAllVideosState, action: { payload: Video[] }) => {
      state.groupVideos = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const AllVideosActions = allVideosSlice.actions

export default allVideosSlice.reducer
