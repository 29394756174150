import clsx from 'clsx'
import { Button } from 'components/Button/Button'
import { ReactComponent as CrossSvg } from 'images/icons/cross.svg'
import { useCallback } from 'react'
import Modal from 'react-modal'

import style from './BaseModal.module.css'

interface IBaseModal {
  disabled: boolean
  isOpen: boolean
  onClose?: () => void
  className?: string
  children?: React.ReactNode
}

const BaseModal: React.FC<IBaseModal> = props => {
  const onClose = useCallback(() => {
    props.onClose && props.onClose()
  }, [props])

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={onClose}
      className={clsx(style.root, props.className)}
      shouldCloseOnOverlayClick={!props.disabled}
      style={{ overlay: { zIndex: 100 } }}
      ariaHideApp={false}
    >
      <Button.Common className={style.btnClose} onClick={onClose} disabled={props.disabled}>
        <CrossSvg />
      </Button.Common>
      {props.children}
    </Modal>
  )
}

export default BaseModal
