import ReportPreview from 'components/ReportPreview/ReportPreview'
import { useUser } from 'hooks/user'
import { useSearchParams } from 'react-router-dom'
import getUserName from 'utils/getUserName'
import { IReportFilters } from 'utils/interfaces'

const PrintReport = () => {
  const [urlParams] = useSearchParams()
  const user = useUser()

  const author = user?.state ? getUserName(user.state) : 'Нет автора'
  const projectId = parseFloat(urlParams.get('projectId') || '')
  const dateStart = urlParams.get('dateStart')
  const dateEnd = urlParams.get('dateEnd')
  const status = (urlParams.get('status') as 'active' | 'completed' | 'all') || 'all'

  const userParam = urlParams.get('user')
  const userId = userParam ? parseFloat(userParam) : undefined
  const date = (urlParams.get('date') as 'inTime' | 'outdated' | 'all') || 'all'
  const filters: IReportFilters = {
    dateStart: dateStart ? new Date(dateStart) : null,
    dateEnd: dateEnd ? new Date(dateEnd) : null,
    status,
    user: userId,
    date,
  }
  const taskIds = urlParams.get('taskIds')?.split(',').map(Number) || []
  const selectedPhotosIndexes = urlParams.get('selectedPhotos')?.split(',').map(Number) || []
  const selectedPhotos: Record<number, number> = {}
  selectedPhotosIndexes.forEach((photoIndex, i) => {
    selectedPhotos[taskIds[i]] = photoIndex
  })
  return <ReportPreview filters={filters} projectId={projectId} author={author} selectedPhotos={selectedPhotos} />
}

export default PrintReport
