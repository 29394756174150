import { useCallback, useEffect, useMemo } from 'react'
import { Layer, LineLayer, Source } from 'react-map-gl'
import { ITrajectories } from 'utils/interfaces'

const VideoRouteOnMap = ({
  map,
  onRouteClicked,
  videoRouteLine,
}: {
  map?: mapboxgl.Map
  onRouteClicked: (coords: mapboxgl.LngLat) => void
  videoRouteLine: ITrajectories
}) => {
  const videoRouteStyle = useMemo<LineLayer>(() => {
    return {
      id: 'video-route-style',
      type: 'line',
      layout: {
        'line-cap': 'square',
      },
      paint: {
        'line-width': 7,
        'line-color': '#7077DF',
        'line-opacity': 1,
      },
    }
  }, [])

  const onMouseEnter = useCallback(
    (e: mapboxgl.MapMouseEvent) => {
      if (!map) {
        return
      }
      map.getCanvas().style.cursor = 'pointer'
    },
    [map]
  )

  const onMouseLeave = useCallback(() => {
    if (!map) {
      return
    }
    map.getCanvas().style.cursor = 'default'
  }, [map])

  const onMouseClick = useCallback(
    (e: mapboxgl.MapMouseEvent) => {
      if (!map) {
        return
      }
      onRouteClicked(e.lngLat)
    },
    [map, onRouteClicked]
  )

  useEffect(() => {
    if (!map) {
      return
    }
    map.on('mouseenter', 'video-route-style', onMouseEnter)
    map.on('mouseleave', 'video-route-style', onMouseLeave)
    map.on('click', 'video-route-style', onMouseClick)
    return () => {
      map.off('mouseenter', 'video-route-style', onMouseEnter)
      map.off('mouseleave', 'video-route-style', onMouseLeave)
      map.off('click', 'video-route-style', onMouseClick)
    }
  }, [map, onMouseEnter, onMouseLeave, onMouseClick])

  return (
    <Source id="videoroute" type="geojson" data={videoRouteLine}>
      <Layer {...videoRouteStyle} />
    </Source>
  )
}

export default VideoRouteOnMap
