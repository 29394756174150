import { useSelector } from 'react-redux'
import { generatePath, useNavigate } from 'react-router-dom'
import { RootState } from 'utils/interfaces'
import ProjectInfo from './ProjectInList/ProjectInfo'
import Dropdown, { IOption } from 'components/Dropdown/Dropdown'
import { Tabs } from '@mui/material'
import TabPanel from 'components/TabPanel/TabPanel'
import { StyledTab } from 'components/StyledTab/StyledTab'
import { useMemo, useState } from 'react'
import store from 'store/store'
import { projectThunks } from 'store/thunks/project'
import { PATH, PROGRESS_TYPES } from 'utils/constants'
import Loader from 'components/Loader/Loader'
import ImportVideoListContainer from './ImportVideoList/ImportVideoListContainer'
import style from './ProjectsList.module.css'
import logoOneSrc from 'images/new/logo1.png'
import Reports from './Reports/Reports'

const ProjectsList = () => {
  const [tab, setTab] = useState(0)
  const navigate = useNavigate()
  const projects = useSelector((state: RootState) => state.allProjects.projects)
  const currentProject = useSelector((state: RootState) => state.project.project)
  const videosLoadingState = useSelector((state: RootState) => state.allVideos.loadingProgress)

  const dropdownProjects = useMemo<Array<IOption>>(() => {
    return [
      ...projects.map(project => {
        return { text: project.name, text2: project.description, value: project.id }
      }),
    ]
  }, [projects])

  const currentProjectOption = useMemo<IOption>(() => {
    return projects.length
      ? { text: currentProject?.name || '', value: currentProject?.id, text2: currentProject?.description }
      : { text: 'Нет доступных проектов', value: undefined }
  }, [currentProject, projects.length])

  const onProjectSelected = (e: IOption) => {
    if (e && e.value) {
      navigate(generatePath(PATH.MAIN_PROJECT, { projectId: e.value.toString() }))
    } else {
      navigate(PATH.MAIN)
      store.dispatch(projectThunks.resetProject())
    }
  }

  return (
    <div className={style.list}>
      <img className={style.logo} src={logoOneSrc} alt="logo" />
      <Dropdown
        className={style.dropdown}
        placeholderClassName={style.placeholder}
        onClickOption={onProjectSelected}
        options={dropdownProjects}
        currentOption={currentProjectOption}
        disabled={projects.length === 0}
      />
      <Tabs
        value={tab}
        onChange={(e, newValue: number) => {
          setTab(newValue)
        }}
        indicatorColor="primary"
        sx={{ height: 40, minHeight: 40, float: 'left' }}
        variant="fullWidth"
      >
        <StyledTab
          label="Видео"
          sx={{
            fontSize: 12,
            fontWeight: '400',
            textTransform: 'none',
            letterSpacing: 0,
            maxWidth: 115,
            minHeight: 40,
          }}
          disableRipple
        />
        <StyledTab
          label="Отчеты"
          sx={{
            fontSize: 12,
            fontWeight: '400',
            textTransform: 'none',
            letterSpacing: 0,
            maxWidth: 115,
            minHeight: 40,
          }}
          disableRipple
        />
        {currentProject?.current_user_data.can_add_video && (
          <StyledTab
            label="Загрузки"
            sx={{
              fontSize: 12,
              fontWeight: '400',
              textTransform: 'none',
              letterSpacing: 0,
              maxWidth: 115,
              minHeight: 40,
            }}
            disableRipple
          />
        )}
      </Tabs>
      <TabPanel value={tab} index={0} disablePadding={true}>
        <div className={style.content}>
          {videosLoadingState === PROGRESS_TYPES.WORK && <Loader />}
          {videosLoadingState === PROGRESS_TYPES.SUCCESS && currentProject && (
            <ProjectInfo key={currentProject.id} project={currentProject} />
          )}
          {videosLoadingState === PROGRESS_TYPES.ERROR && <div className={style.errorBlock}>Ошибка загрузки</div>}
        </div>
      </TabPanel>
      <TabPanel value={tab} index={1} disablePadding={true}>
        <div className={style.content}>{currentProject && <Reports currentProjectId={currentProject.id} />}</div>
      </TabPanel>
      <TabPanel value={tab} index={2} disablePadding={true}>
        <div className={style.content}>
          {currentProject && <ImportVideoListContainer currentProjectId={currentProject.id} />}
        </div>
      </TabPanel>
    </div>
  )
}

export default ProjectsList
