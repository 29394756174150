import { saveAs } from 'file-saver'
import { ITempPoint } from './RouteMarking'
import toTimestamp from 'utils/secondsToTimestamp'

const savePointsToCsv = (filename: string, points: Array<ITempPoint>) => {
  let text = ''

  points.forEach(point => {
    text += `${toTimestamp(point.frameNumber)}; ${point.lat}; ${point.long}; \n`
  })
  const blob = new Blob([text], { type: 'text/plain;charset=utf-8' })
  saveAs(blob, filename + '.txt', { autoBom: true })
}

export default savePointsToCsv
