import { ReactComponent as EditSvg } from 'images/icons/edit.svg'
import { ReactComponent as TimeSvg } from 'images/icons/time.svg'
import { ReactComponent as DateSvg } from 'images/icons/date.svg'
import { ReactComponent as AvatarSvg } from 'images/icons/avatar.svg'
import { ReactComponent as TaskCompletedSvg } from 'images/icons/taskCompleted.svg'
import { MainProjectAnnotationService, VideoAnnotationReadInstance } from 'api/schema'
import store from 'store/store'
import { VideoActions } from 'store/actions/video'
import { formatDistance } from 'date-fns'
import dateFnsFormat from 'date-fns/format'
import ru from 'date-fns/locale/ru'
import { Button } from 'components/Button/Button'
import { useCallback, useState } from 'react'
import EditTask from '../EditTask/EditTask'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'utils/interfaces'
import clsx from 'clsx'
import { generatePath, useNavigate } from 'react-router-dom'
import { PATH } from 'utils/constants'
import style from './TaskItem.module.css'
import { isOverdue } from 'utils/isTaskOverdue'
import getUserName from 'utils/getUserName'

const Annotation = ({
  annotation: task,
  onEdited,
}: {
  annotation: VideoAnnotationReadInstance
  onEdited: () => void
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showIcons, setShowIcons] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const frameNumber = useSelector((state: RootState) => state.video.frameNumber)
  const editingId = useSelector((state: RootState) => state.video.editingAnnotationId)
  const project = useSelector((state: RootState) => state.project.project)
  const video = useSelector((state: RootState) => state.video.video)
  const frame = task.frames.find(frame => frame.frame.video_id === video?.id)
  const picketNumber = useSelector((state: RootState) => {
    if (!frame) {
      return null
    }
    return frame.frame.mark_number
  })

  const seekFrameNumber = useCallback(() => {
    if (!frame) {
      return
    }
    const seconds = frame.frame.timestamp_seconds
    window.dispatchEvent(new CustomEvent('videoSeekFrameEvent', { detail: seconds }))
  }, [task.frames, video?.id])

  const onAnnotationDelete = useCallback(
    async (e: any) => {
      try {
        setDisabled(true)
        e.stopPropagation()
        await MainProjectAnnotationService.mainVideoAnnotationDestroy(task.id)
        onEdited()
        setDisabled(false)
      } catch (error) {
        setDisabled(false)
        console.error(error)
      }
    },
    [task.id, onEdited]
  )

  const onAnnotationEdit = useCallback(
    async (e: any) => {
      seekFrameNumber()
      setShowIcons(false)
      window.dispatchEvent(new Event('videoPausedEvent'))
      e.stopPropagation()
      store.dispatch(VideoActions.setEditingAnnotationId(task.id))
    },
    [task.id, seekFrameNumber]
  )

  const onSave = useCallback(
    async (text: string, executorId?: number, executionDate?: string | null, factDate?: string | null) => {
      try {
        setDisabled(true)
        await MainProjectAnnotationService.mainVideoAnnotationPartialUpdate(task.id, {
          project_id: task.project_id,
          point: task.point,
          text,
          first_appeared_at: task.first_appeared_at,
          scheduled_to_resolve_at: executionDate,
          resolved_at: factDate,
          assignee_to_id: executorId,
        })
        store.dispatch(VideoActions.setEditingAnnotationId())
        onEdited()
        setDisabled(false)
      } catch (error) {
        console.error(error)
      }
    },
    [task.id, task.project_id, task.point, task.first_appeared_at, onEdited]
  )

  const onAnnotationClicked = useCallback(() => {
    store.dispatch(VideoActions.setEditingAnnotationId())
    window.dispatchEvent(new Event('videoPausedEvent'))
    if (!project || !video) {
      return
    }

    if (!frame && task.frames[0]) {
      const frameFromAnotherVideo = task.frames[0]
      const path =
        generatePath(PATH.VIDEO, { projectId: String(project?.id), id: String(frameFromAnotherVideo.video_id) }) +
        '?t=' +
        frameFromAnotherVideo.frame.timestamp_seconds
      navigate(path)
    } else {
      seekFrameNumber()
    }
  }, [frame, navigate, project, seekFrameNumber, task.frames, video])

  const onOldTimeClicked = useCallback(() => {
    seekFrameNumber()
    window.dispatchEvent(new Event('videoPausedEvent'))
  }, [seekFrameNumber])

  const formatDateTime = (date: string) => {
    return dateFnsFormat(new Date(date), 'dd LLL yyyy  HH:MM', { locale: ru })
  }

  const formatDate = (date: string) => {
    return dateFnsFormat(new Date(date), 'dd.MM.yyyy', { locale: ru })
  }

  const formatDateWithMonthName = (date: string) => {
    return dateFnsFormat(new Date(date), 'dd LLL yyyy', { locale: ru })
  }

  return (
    <>
      {editingId === task.id && (
        <EditTask
          time={frameNumber}
          // oldTime={task.start_timestamp}
          text={task.text || ''}
          onCancel={() => {
            dispatch(VideoActions.setEditingAnnotationId())
          }}
          executorId={task.assignee_to_id || 0}
          dateExecution={task.scheduled_to_resolve_at}
          dateFact={task.resolved_at}
          complete={task.is_resolved}
          disabled={disabled}
          onSave={onSave}
          onDelete={onAnnotationDelete}
          onOldTimeClicked={onOldTimeClicked}
        />
      )}
      {editingId !== task.id && (
        <div
          className={style.annotation}
          onClick={onAnnotationClicked}
          onMouseEnter={() => setShowIcons(true)}
          onMouseLeave={() => setShowIcons(false)}
        >
          <div className={style.annotationTextBlock}>
            <div className={style.annotationTopTextRow}>
              <span className={clsx(style.marginRight, style.noWrap)}>
                #{task.id} (от {formatDateWithMonthName(task.created_at)})
              </span>
              {frame && (
                <>
                  <TimeSvg className={clsx(style.marginRight, style.marginLeft)} />
                  <span>{frame?.frame.timestamp}</span>
                </>
              )}
              <span className={clsx(style.marginRight, style.marginLeft)}>
                {picketNumber ? 'ПК' + picketNumber : ''}
              </span>
              <AvatarSvg className={clsx(style.marginRight, style.marginLeft)} />
              <span
                className={style.ellipsis}
                title={`Изменил ${task.updated_by.email} \n${formatDateTime(task.updated_at)}`}
              >
                {formatDistance(new Date(task.updated_at), new Date(), { locale: ru, addSuffix: true })}
              </span>
            </div>
            <div className={style.annotationTextBlockDesc}>{task.text}</div>
            <div className={style.annotationTextBlockTime}>
              {task.assignee_to && <span className={style.marginRight}>{getUserName(task.assignee_to)}</span>}
              {!task.assignee_to && <span className={style.marginRight}>Без исполнителя</span>}
              <DateSvg className={clsx(style.marginRight, style.marginLeft)} />
              {task.scheduled_to_resolve_at && (
                <span className={style.marginRight}>{formatDate(task.scheduled_to_resolve_at)}</span>
              )}
              {!task.scheduled_to_resolve_at && <span className={style.marginRight}>Без срока</span>}
              {isOverdue(task) && <span className={style.overdue}>(просрочено)</span>}
              {task.resolved_at && task.is_resolved && (
                <>
                  <TaskCompletedSvg className={clsx(style.marginLeft, style.marginRight)} />
                  <span className={clsx(style.taskCompletedText, style.marginRight)}>
                    {formatDate(task.resolved_at)}
                  </span>
                </>
              )}
            </div>
          </div>
          {showIcons && project?.current_user_data.can_add_video_annotation && (
            <Button.Standart className={clsx(style.iconButton, style.editIconButton)} onClick={onAnnotationEdit}>
              <EditSvg />
            </Button.Standart>
          )}
        </div>
      )}
    </>
  )
}

export default Annotation
