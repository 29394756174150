import { useMemo } from 'react'
import { Layer, LineLayer, Source } from 'react-map-gl'
import { ITrajectories } from 'utils/interfaces'

const SumRouteOnMap = ({ sumRouteLine }: { sumRouteLine: ITrajectories }) => {
  const transferRouteStyle = useMemo<LineLayer>(() => {
    return {
      id: 'route-style',
      type: 'line',
      layout: {
        'line-cap': 'square',
      },
      paint: {
        'line-width': 7,
        'line-color': '#E5E5EA',
        'line-opacity': 1,
      },
    }
  }, [])

  return (
    <>
      <Source id="sumrouteline" type="geojson" data={sumRouteLine}>
        <Layer {...transferRouteStyle} />
      </Source>
    </>
  )
}

export default SumRouteOnMap
