import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker'
import { ReactComponent as CheckFilterSvg } from 'images/icons/checkFilter.svg'
import { useCallback, useEffect, useState } from 'react'
import Dropdown, { IOption } from 'components/Dropdown/Dropdown'
import getUserName from 'utils/getUserName'
import { MainReportService, ProjectDetailed, ProjectUser, UserInline } from 'api/schema'
import { IReportFilters, RootState } from 'utils/interfaces'
import { useSelector } from 'react-redux'
import { Button } from 'components/Button/Button'
import clsx from 'clsx'
import style from './Reports.module.css'
import './DatePicker.css'
import { useUser } from 'hooks/user'
import { Link } from 'react-router-dom'
import { PATH, PROGRESS_TYPES } from 'utils/constants'
import ReportPreview from 'components/ReportPreview/ReportPreview'
import { Report } from 'api/schema'
import Loader from 'components/Loader/Loader'
import dateFnsFormat from 'date-fns/format'
import ru from 'date-fns/locale/ru'
import _ from 'lodash'
import ActionsMenu, { ActionsMenuItem } from 'components/ActionsMenu/ActionsMenu'

// import { ReactComponent as RemoveSvg } from 'images/icons/delete.svg'

type FiltersProps = {
  project: ProjectDetailed
  filters: IReportFilters
  setFilters: (filters: IReportFilters) => void
}

const Filters = ({ project, filters, setFilters }: FiltersProps) => {
  const [executor, setExecutor] = useState<ProjectUser | UserInline | undefined>()

  const userOptions: IOption[] = [
    { value: undefined, text: 'Все исполнители' },
    ...project.users.map(user => ({ value: user.id, text: getUserName(user) })),
  ]

  const onActiveClicked = (e: React.MouseEvent) => {
    e.stopPropagation()
    const newFilters = { ...filters }
    newFilters.status = newFilters.status === 'active' ? 'all' : 'active'
    setFilters(newFilters)
  }

  const onCompletedClicked = (e: React.MouseEvent) => {
    e.stopPropagation()
    const newFilters = { ...filters }
    newFilters.status = newFilters.status === 'completed' ? 'all' : 'completed'
    setFilters(newFilters)
  }

  const onStartDateChanged = (newDate: Date | null) => {
    const newFilters = { ...filters }
    newFilters.dateStart = newDate
    setFilters(newFilters)
  }

  const onEndDateChanged = (newDate: Date | null) => {
    const newFilters = { ...filters }
    newFilters.dateEnd = newDate
    setFilters(newFilters)
  }

  const onInTimeClicked = (e: React.MouseEvent) => {
    e.stopPropagation()
    const newFilters = { ...filters }
    newFilters.date = newFilters.date === 'inTime' ? 'all' : 'inTime'
    setFilters(newFilters)
  }

  const onOutdatedClicked = (e: React.MouseEvent) => {
    e.stopPropagation()
    const newFilters = { ...filters }
    newFilters.date = newFilters.date === 'outdated' ? 'all' : 'outdated'
    setFilters(newFilters)
  }

  return (
    <div className={style.createNewReport}>
      <div className={style.subtitle}>Выбрать задачи за период:</div>
      <div className={style.datesRow}>
        <CustomDatePicker
          date={filters.dateStart}
          onChange={newDate => onStartDateChanged(newDate)}
          disabled={false}
          placeholderText="Выберите дату"
        />
        <CustomDatePicker
          date={filters.dateEnd}
          onChange={newDate => onEndDateChanged(newDate)}
          disabled={false}
          placeholderText="Выберите дату"
        />
      </div>
      <div className={style.block}>
        <div className={style.filterBlock}>
          <div className={style.filterTitle}>По статусу:</div>
          <div className={style.filterItem} onClick={onActiveClicked}>
            Активные {filters.status === 'active' && <CheckFilterSvg className={style.check} />}
          </div>
          <div className={style.filterItem} onClick={onCompletedClicked}>
            Завершенные {filters.status === 'completed' && <CheckFilterSvg className={style.check} />}
          </div>
        </div>
        <div className={style.filterBlock}>
          <div className={style.filterTitle}>По пользователю:</div>
          <Dropdown
            className={style.dropdown}
            placeholderClassName={style.placeholder}
            listClassName={style.list}
            placeholder="Выбрать исполнителя"
            options={userOptions}
            currentOption={executor ? { value: executor.id, text: getUserName(executor) } : undefined}
            onClickOption={(e: IOption) => {
              setExecutor(project?.users.find(user => user.id === e.value))
              const newFilters = { ...filters }
              newFilters.user = e.value as number
              setFilters(newFilters)
            }}
          />
        </div>
        <div className={style.filterBlock}>
          <div className={style.filterTitle}>По дате:</div>
          <div className={style.filterItem} onClick={onInTimeClicked}>
            Не просроченные {filters.date === 'inTime' && <CheckFilterSvg className={style.check} />}
          </div>
          <div className={style.filterItem} onClick={onOutdatedClicked}>
            Просроченные {filters.date === 'outdated' && <CheckFilterSvg className={style.check} />}
          </div>
        </div>
      </div>
    </div>
  )
}

const Reports = ({ currentProjectId }: { currentProjectId?: number }) => {
  const [createNewReport, setCreateNewReport] = useState(false)
  const [loading, setLoading] = useState(PROGRESS_TYPES.IDLE)
  const [reports, setReports] = useState<Report[]>([])
  const project = useSelector((state: RootState) => state.project.project)
  const user = useUser()
  const [filters, setFilters] = useState<IReportFilters>({
    dateStart: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    dateEnd: new Date(),
    status: 'all',
    user: undefined,
    date: 'all',
  })
  const [selectedPhotos, setSelectedPhotos] = useState<Record<number, number>>({})
  const formatDateTime = (date: string) => {
    return dateFnsFormat(new Date(date), 'dd LLL yyyy  HH:mm', { locale: ru })
  }
  const fetchReports = useCallback(async () => {
    if (!currentProjectId) {
      return
    }
    try {
      setLoading(PROGRESS_TYPES.WORK)
      const reportsRaw = await MainReportService.mainReportList(undefined, undefined, undefined, [currentProjectId])
      if (reportsRaw.results) {
        setReports(reportsRaw.results)
      }
      setLoading(PROGRESS_TYPES.SUCCESS)
    } catch (error) {
      setLoading(PROGRESS_TYPES.ERROR)
    }
  }, [currentProjectId])

  useEffect(() => {
    fetchReports()
  }, [fetchReports])

  const reportUrl = () => {
    if (!project) return
    const dateStart = filters.dateStart ? '&dateStart=' + filters.dateStart.toISOString() : ''
    const dateEnd = filters.dateEnd ? '&dateEnd=' + filters.dateEnd.toISOString() : ''
    const taskIds = Object.keys(selectedPhotos)
    const selectedPhotosIndexes = taskIds.map(taskId => selectedPhotos[parseInt(taskId)])
    return (
      PATH.PRINT_REPORT +
      `?projectId=${project.id}${dateStart}${dateEnd}&status=${filters.status}&user=${filters.user}&date=${
        filters.date
      }&taskIds=${taskIds.join(',')}&selectedPhotos=${selectedPhotosIndexes.join(',')}`
    )
  }

  useEffect(() => {
    const fetchReportsSilent = async () => {
      if (!currentProjectId) return
      try {
        const reportsRaw = await MainReportService.mainReportList(undefined, undefined, undefined, [currentProjectId])
        if (reportsRaw.results) {
          setReports(reportsRaw.results)
        }
      } catch (error) {}
    }

    let timerIds: number[] = []

    const checkAndExecute = () => {
      const isProcessing = reports.some(report => report.status === 'processing')
      if (isProcessing) {
        timerIds.push(
          _.delay(() => {
            fetchReportsSilent()
            timerIds.push(
              _.delay(() => {
                fetchReportsSilent()
                timerIds.push(
                  _.delay(() => {
                    fetchReportsSilent()
                  }, 15000)
                )
              }, 10000)
            )
          }, 5000)
        )
      } else {
        timerIds.forEach(clearTimeout)
        timerIds = []
      }
    }
    checkAndExecute()
    return () => {
      timerIds.forEach(clearTimeout)
    }
  }, [currentProjectId, reports])

  const onSaveClicked = async () => {
    if (!currentProjectId) {
      return
    }
    try {
      setLoading(PROGRESS_TYPES.WORK)
      await MainReportService.mainReportAnnotationsCreate({
        project_id: currentProjectId,
        param__date_after: filters.dateStart?.toISOString(),
        param__date_before: filters.dateEnd?.toISOString(),
        param__user_id: filters.user ? [filters.user] : undefined,
        param__is_expired: filters.date === 'outdated',
        param__is_resolved: filters.status === 'completed',
        url: reportUrl(),
      })
      const reportsRaw = await MainReportService.mainReportList(undefined, undefined, undefined, [currentProjectId])
      if (reportsRaw.results) {
        setReports(reportsRaw.results)
      }
      setLoading(PROGRESS_TYPES.SUCCESS)
      setCreateNewReport(false)
    } catch (error) {
      setLoading(PROGRESS_TYPES.ERROR)
    }
  }
  const onReportDelete = async (report: Report) => {
    if (!currentProjectId) {
      return
    }
    try {
      setLoading(PROGRESS_TYPES.WORK)
      await MainReportService.mainReportDestroy(report.id)
      setLoading(PROGRESS_TYPES.SUCCESS)
    } catch (error) {
      setLoading(PROGRESS_TYPES.ERROR)
    }
    fetchReports()
  }

  return (
    <div className={style.content}>
      {loading === PROGRESS_TYPES.WORK && <Loader />}
      {loading === PROGRESS_TYPES.ERROR && 'Ошибка загрузки'}
      {!createNewReport && loading !== PROGRESS_TYPES.WORK && (
        <div className={style.createNewButton} onClick={() => setCreateNewReport(!createNewReport)}>
          Создать новый отчет
        </div>
      )}
      {!createNewReport &&
        loading === PROGRESS_TYPES.SUCCESS &&
        reports.map(report => (
          <Link
            to={report.file || ''}
            target={report.file ? '_blank' : ''}
            rel="noopener noreferrer"
            className={report.file ? style.reportItem : clsx(style.reportItem, style.reportItemDisabled)}
            key={report.id}
          >
            <div>{`${report.title || ''} Отчёт от ${formatDateTime(report.created_at)}`}</div>
            {report.status === 'processing' && <div className={style.processing}>генерируем PDF...</div>}
            {report.status === 'error' && <div className={style.processing}>Ошибка</div>}
            <div className={style.actionsMenu}>
              <ActionsMenu>
                <ActionsMenuItem onClick={() => onReportDelete(report)}>
                  <span className={style.actionsItem}>Удалить</span>
                </ActionsMenuItem>
              </ActionsMenu>
            </div>
          </Link>
        ))}
      {createNewReport && project && loading !== PROGRESS_TYPES.WORK && (
        <>
          <Filters project={project} filters={filters} setFilters={setFilters} />
          <div className={style.buttons}>
            <Button.Standart
              className={clsx(style.button, style.buttonCancel)}
              onClick={() => setCreateNewReport(false)}
            >
              Отмена
            </Button.Standart>
            <Button.Standart className={clsx(style.button)} onClick={onSaveClicked}>
              Сохранить
            </Button.Standart>
          </div>
        </>
      )}
      {createNewReport && project && (
        <div className={style.container}>
          <ReportPreview
            filters={filters}
            projectId={project.id}
            author={user?.state ? getUserName(user.state) : 'Нет автора'}
            selectedPhotos={selectedPhotos}
            onSelectedPhotosChange={setSelectedPhotos}
          />
        </div>
      )}
    </div>
  )
}

export default Reports
