import { createSlice } from '@reduxjs/toolkit'
import { Project } from 'api/schema'
import { PROGRESS_TYPES } from 'utils/constants'
import { IAllProjectsState } from 'utils/interfaces'

const initialState: IAllProjectsState = {
  loadingProgress: PROGRESS_TYPES.IDLE,
  projects: [],
}

export const allProjectsSlice = createSlice({
  name: 'allProjects',
  initialState,
  reducers: {
    setLoadingProgress: (state: IAllProjectsState, action: { payload: PROGRESS_TYPES }) => {
      state.loadingProgress = action.payload
    },
    setAllProjects: (state: IAllProjectsState, action: { payload: Project[] }) => {
      state.projects = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const AllProjectsActions = allProjectsSlice.actions

export default allProjectsSlice.reducer
