import { Button } from 'components/Button/Button'
import style from './PicketButton.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'utils/interfaces'
import { PATH, PROGRESS_TYPES } from 'utils/constants'
import { useCallback, useMemo, useRef } from 'react'
import { MainProjectVideoService, ProjectMark, VideoFrame, VideoFrameSearch } from 'api/schema'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'
import clsx from 'clsx'
import { ReactComponent as DownArrowSvg } from 'images/icons/arrowDown.svg'
import { ReactComponent as UpArrowSvg } from 'images/icons/arrowUp.svg'
import { VideoActions } from 'store/actions/video'

const PicketButton = ({ closeAnnotationsCb }: { closeAnnotationsCb: () => void }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const scrollableRef = useRef<HTMLDivElement>(null)

  const frames = useSelector((state: RootState) => state.video.frames)
  const projectLoadingProgress = useSelector((state: RootState) => state.project.loadingProgress)
  const videoLoadingProgress = useSelector((state: RootState) => state.video.loadingProgress)
  const project = useSelector((state: RootState) => state.project.project)
  const video = useSelector((state: RootState) => state.video.video)
  const pickets = useSelector((state: RootState) => state.project.pickets)
  const showPickets = useSelector((state: RootState) => state.video.showPickets)
  const [urlParams] = useSearchParams()

  const setShowPickets = useCallback(
    (show: boolean) => {
      dispatch(VideoActions.setShowPickets(show))
    },
    [dispatch]
  )

  const currentFrame = useMemo(() => {
    const picketTime = urlParams.get('t') || '0'
    const frame = frames.find(frame => frame.timestamp_seconds === parseFloat(picketTime))
    return frame
  }, [frames, urlParams])

  const picketText = useMemo(() => {
    if (
      projectLoadingProgress !== PROGRESS_TYPES.SUCCESS ||
      videoLoadingProgress !== PROGRESS_TYPES.SUCCESS ||
      !pickets
    ) {
      return '-'
    }
    return 'ПК' + currentFrame?.mark_number
  }, [currentFrame?.mark_number, pickets, projectLoadingProgress, videoLoadingProgress])

  const picketFramesDict = useMemo(() => {
    let unique = new Map(frames.map(frame => [frame.mark_number, frame]))
    let uniqueItems = Array.from(unique.values())
    const dict = uniqueItems.reduce((acc: Record<number, VideoFrame>, frame) => {
      if (frame.mark_number) {
        acc[frame.mark_number] = frame
      }
      return acc
    }, {})
    return dict
  }, [frames])

  const onClick = useCallback(
    (show: boolean) => {
      setShowPickets(show)
      if (scrollableRef.current) {
        const curPicketNumber = pickets?.findIndex(picket => picket.number === currentFrame?.mark_number) || 0
        scrollableRef.current.scrollTop = 24 * curPicketNumber
      }
      closeAnnotationsCb()
    },
    [closeAnnotationsCb, currentFrame?.mark_number, pickets, setShowPickets]
  )

  const onPicketClicked = useCallback(
    (frame: VideoFrame) => {
      window.dispatchEvent(new Event('videoPausedEvent'))
      window.dispatchEvent(new CustomEvent('videoSeekFrameEvent', { detail: frame.timestamp_seconds }))
      setShowPickets(false)
    },
    [setShowPickets]
  )

  const onOtherPicketClicked = useCallback(
    async (picket: ProjectMark) => {
      window.dispatchEvent(new Event('videoPausedEvent'))
      setShowPickets(false)
      try {
        // Грязный хак из-за того, что codegen неправильно реализует спецификацию OpenAPI 3.0.3: при style=form и explode=false массив чисел должен в запросе идти через запятую
        const videoFramesRaw = await MainProjectVideoService.mainVideoFrameSearchList(
          [picket.project_id],
          100,
          undefined,
          [`${picket.point.coordinates}` as unknown as number]
        )
        const videoFramesRawFiltered = videoFramesRaw.filter(videoFrame => videoFrame.mark_number === picket.number)
        if (videoFramesRawFiltered.length === 0) {
          console.warn('No videos found with this picket')
          return
        }

        const currentDate = new Date(video?.date || '')
        const minDateDiffVideo = videoFramesRawFiltered.reduce(
          (closest: any, current: VideoFrameSearch) => {
            const currentDiff: number = Math.abs(new Date(current.video.date || '').getTime() - currentDate.getTime())
            const closestDiff: number = closest.diff || Infinity
            return currentDiff < closestDiff ? { obj: current, diff: currentDiff } : closest
          },
          { obj: undefined, diff: Infinity }
        ).obj!
        const path =
          generatePath(PATH.VIDEO, { projectId: String(project?.id), id: String(minDateDiffVideo.video_id) }) +
          '?t=' +
          minDateDiffVideo.timestamp_seconds
        navigate(path)
      } catch (error) {
        console.error('Error fetching other videos with this picket: ', error)
      }
    },
    [navigate, setShowPickets, video?.date]
  )

  return (
    <>
      <Button.Outlined className={style.picketButton} onClick={() => onClick(!showPickets)}>
        <span className={style.buttonText}>
          {picketText} {showPickets ? <UpArrowSvg /> : <DownArrowSvg />}
        </span>
        <div className={style.block} ref={scrollableRef} style={{ visibility: showPickets ? 'visible' : 'hidden' }}>
          {pickets?.map(picket => {
            if (picketFramesDict[picket.number]) {
              const frame = picketFramesDict[picket.number]
              return (
                <span key={frame.id} className={style.picketInList} onClick={() => onPicketClicked(frame)}>
                  {'ПК' + frame.mark_number}
                </span>
              )
            } else {
              return (
                <span
                  key={picket.id}
                  className={clsx(style.picketInList, style.picketFromOtherVideo)}
                  onClick={() => onOtherPicketClicked(picket)}
                >
                  {'ПК' + picket.number}
                </span>
              )
            }
          })}
        </div>
      </Button.Outlined>
    </>
  )
}

export default PicketButton
