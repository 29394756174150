import { Button } from 'components/Button/Button'
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import Map, { MapRef, MapboxEvent, NavigationControl } from 'react-map-gl'
import { TOKEN_MAPBOX } from 'utils/constants'
import { STYLES } from 'utils/constants'
import { IMapboxElement } from 'utils/interfaces'
import style from './MapboxCommon.module.css'
import clsx from 'clsx'
// import MapboxLanguage from '@mapbox/mapbox-gl-language'

import 'mapbox-gl/dist/mapbox-gl.css'

interface MapboxProps {
  onMapMoved?: () => void
  onMapInited?: () => void
  children?: React.ReactNode
}

const MapboxCommon = React.forwardRef<IMapboxElement, MapboxProps>((props, ref) => {
  const mapRef = useRef<MapRef>(null)
  const [mapStyle, setMapStyle] = useState<string>(STYLES.VECTOR)

  const initMap = (e: MapboxEvent<undefined>) => {
    props.onMapInited && props.onMapInited()

    // const map = e.target as MapboxMap
    // const browserLanguage = navigator.language.split('-')[0]
    // const language = new MapboxLanguage({ defaultLanguage: browserLanguage })
    // map.addControl(language)
    // const style = language.setLanguage(map.getStyle(), 'ru')
    // map.setStyle(style)
  }

  useEffect(() => {
    const map = mapRef?.current?.getMap()
    props.onMapMoved && map?.on('moveend', props.onMapMoved)
    return () => {
      props.onMapMoved && map?.off('moveend', props.onMapMoved)
    }
  }, [props.onMapMoved])

  useImperativeHandle(ref, () => ({
    getMap() {
      return mapRef?.current?.getMap()
    },
  }))

  const onMapLoad = useCallback((e: MapboxEvent<undefined>) => {
    initMap(e)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSatelliteClicked = () => {
    setMapStyle(mapStyle === STYLES.SATELLITE ? STYLES.VECTOR : STYLES.SATELLITE)
  }

  return (
    <Map
      ref={mapRef}
      onLoad={onMapLoad}
      initialViewState={{
        longitude: 49.14,
        latitude: 55.524,
        zoom: 10,
      }}
      mapStyle={mapStyle}
      mapboxAccessToken={TOKEN_MAPBOX}
      attributionControl={false}
    >
      <NavigationControl showCompass={false} position={'bottom-right'} />
      <Button.Standart className={style.satelliteButton} onClick={onSatelliteClicked}>
        <span className={clsx('mapboxgl-ctrl-icon', style.icon)} title="Спутник/Вектор" />
      </Button.Standart>
      {props.children}
    </Map>
  )
})

export default MapboxCommon
