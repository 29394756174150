import { configureStore } from '@reduxjs/toolkit'
import { RootState } from 'utils/interfaces'

import projectReducer from './actions/project'
import allProjectsReducer from './actions/allProjects'
import allVideosReducer from './actions/allVideos'
import videoReducer from './actions/video'

export default configureStore<RootState>({
  reducer: {
    project: projectReducer,
    allProjects: allProjectsReducer,
    allVideos: allVideosReducer,
    video: videoReducer,
  },
})
