import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { MainProjectVideoService } from 'api/schema'
import { AllVideosActions } from 'store/actions/allVideos'
import { PROGRESS_TYPES } from 'utils/constants'
import { RootState } from 'utils/interfaces'

const fetchAllVideos = (projectId?: Array<number>) => {
  return async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    try {
      dispatch(AllVideosActions.setLoadingProgress(PROGRESS_TYPES.WORK))
      const allVideosRaw = await MainProjectVideoService.mainVideoList(undefined, undefined, undefined, 2000, projectId)
      dispatch(AllVideosActions.setAllVideos(allVideosRaw.results || []))
      dispatch(AllVideosActions.setLoadingProgress(PROGRESS_TYPES.SUCCESS))
    } catch (error) {
      dispatch(AllVideosActions.setLoadingProgress(PROGRESS_TYPES.ERROR))
    }
  }
}

const fetchGroupVideos = (groupId?: Array<number | null>) => {
  return async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    try {
      dispatch(AllVideosActions.setLoadingProgress(PROGRESS_TYPES.WORK))
      const videosRaw = await MainProjectVideoService.mainVideoList(groupId, undefined, undefined, 2000, undefined)
      dispatch(AllVideosActions.setGroupVideos(videosRaw.results || []))
      dispatch(AllVideosActions.setLoadingProgress(PROGRESS_TYPES.SUCCESS))
    } catch (error) {
      dispatch(AllVideosActions.setLoadingProgress(PROGRESS_TYPES.ERROR))
    }
  }
}

export const allVideosThunks = {
  fetchAllVideos,
  fetchGroupVideos,
}
