import { useCallback, useEffect, useMemo } from 'react'
import { Layer, LineLayer, Source } from 'react-map-gl'
import { ITrajectory } from 'utils/interfaces'

const CenterLineOnMap = ({
  map,
  centerLine,
  onRouteClicked,
}: {
  map?: mapboxgl.Map
  centerLine: ITrajectory
  onRouteClicked: (coords: mapboxgl.LngLat) => void
}) => {
  const centerLineStyle = useMemo<LineLayer>(() => {
    return {
      id: 'centerline-style',
      type: 'line',
      layout: {
        'line-cap': 'square',
      },
      paint: {
        'line-width': 6,
        'line-color': '#FDE29D',
        'line-opacity': 1,
      },
    }
  }, [])

  const onMouseEnter = useCallback(
    (e: mapboxgl.MapMouseEvent) => {
      if (!map) {
        return
      }
      map.getCanvas().style.cursor = 'pointer'
    },
    [map]
  )

  const onMouseLeave = useCallback(() => {
    if (!map) {
      return
    }
    map.getCanvas().style.cursor = 'default'
  }, [map])

  const onMouseClick = useCallback(
    (e: mapboxgl.MapMouseEvent) => {
      if (!map) {
        return
      }
      if (
        !map
          .queryRenderedFeatures(e.point)
          .some(feature => feature.source === 'points' || feature.source === 'selectedPoint')
      ) {
        onRouteClicked(e.lngLat)
      }
    },
    [map, onRouteClicked]
  )

  useEffect(() => {
    if (!map) {
      return
    }
    map.on('mouseenter', 'centerline-style', onMouseEnter)
    map.on('mouseleave', 'centerline-style', onMouseLeave)
    map.on('click', 'centerline-style', onMouseClick)
    return () => {
      map.off('mouseenter', 'centerline-style', onMouseEnter)
      map.off('mouseleave', 'centerline-style', onMouseLeave)
      map.off('click', 'centerline-style', onMouseClick)
    }
  }, [map, onMouseEnter, onMouseLeave, onMouseClick])

  return (
    <Source id="centerline" type="geojson" data={centerLine}>
      <Layer {...centerLineStyle} />
    </Source>
  )
}

export default CenterLineOnMap
