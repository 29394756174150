import VideoPlayer from 'components/VIdeoPlayer/VideoPlayer'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useParams, useSearchParams } from 'react-router-dom'
import store from 'store/store'
import { videoThunks } from 'store/thunks/video'
import { projectThunks } from 'store/thunks/project'
import { RootState } from 'utils/interfaces'
import OtherVideosButton from './OtherVideosButton/OtherVideosButton'
import OtherVideosBlock from './OtherVideosBlock/OtherVideosBlock'
import MiniMap from './MiniMap/MiniMap'
import { VideoActions } from 'store/actions/video'
import Loader from 'components/Loader/Loader'

import TopBlock from './TopBlock/TopBlock'
import { useNavigate } from 'react-router-dom'
import { PATH, PROGRESS_TYPES } from 'utils/constants'
import style from './Video.module.css'
import { allVideosThunks } from 'store/thunks/allVideos'

const Video: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [urlParams] = useSearchParams()
  const projectId = parseFloat(params.projectId || '')
  const videoId = parseFloat(params.id || '')
  const dispatch = useDispatch()

  const video = useSelector((state: RootState) => state.video.video)
  const videosFromGroup = useSelector((state: RootState) => state.allVideos.groupVideos)
  const frames = useSelector((state: RootState) => state.video.frames)
  const showOtherVideos = useSelector((state: RootState) => state.video.showOtherVideos)
  const playerIsReady = useSelector((state: RootState) => state.video.inited)
  const projectLoadingProgress = useSelector((state: RootState) => state.project.loadingProgress)
  const videoLoadingProgress = useSelector((state: RootState) => state.video.loadingProgress)
  const videoPlaybackRate = useSelector((state: RootState) => state.video.playbackRate)
  const videoPlaying = useSelector((state: RootState) => state.video.isPlaying)
  const [videoInitSeeked, setVideoInitSeeked] = useState(false)

  useEffect(() => {
    store.dispatch(videoThunks.fetchVideo(videoId))
    store.dispatch(allVideosThunks.fetchGroupVideos([video?.group_id || null]))
  }, [video?.group_id, videoId])

  useEffect(() => {
    if (playerIsReady) {
      const newFrameNumberRaw = urlParams.get('t')
      if (newFrameNumberRaw) {
        const newFrameNumber = parseFloat(newFrameNumberRaw)
        window.dispatchEvent(new CustomEvent('videoSeekFrameEvent', { detail: newFrameNumber }))
        store.dispatch(VideoActions.setFrameNumber(newFrameNumber))
      }
    } else {
      setVideoInitSeeked(false)
    }
  }, [playerIsReady])

  useEffect(() => {
    if (video?.project_id) {
      store.dispatch(projectThunks.fetchProject(video?.project_id))
    }
  }, [video?.project_id])

  useEffect(() => {
    setVideoInitSeeked(false)
  }, [video])

  const onTimeUpdate = useCallback(
    (time: number) => {
      const frameNumberFromVideo = Math.floor(time)
      if (frameNumberFromVideo >= frames.length) {
        return
      }
      if (!videoInitSeeked) {
        const frameNumberFromUrl = parseFloat(urlParams.get('t') || '0')
        if (frameNumberFromVideo === frameNumberFromUrl) {
          setVideoInitSeeked(true)
        }
      } else {
        store.dispatch(VideoActions.setFrameNumber(frameNumberFromVideo))
        navigate(`?t=${frameNumberFromVideo.toString()}`, { replace: true })
      }
    },
    [videoInitSeeked, frames.length, navigate, urlParams]
  )

  useEffect(() => {
    const frameNumberFromUrl = parseFloat(urlParams.get('t') || '0')
    store.dispatch(VideoActions.setFrameNumber(frameNumberFromUrl))

    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'ArrowLeft') {
        window.dispatchEvent(new CustomEvent('videoSeekBackwardEvent'))
      } else if (event.key === 'ArrowRight') {
        window.dispatchEvent(new CustomEvent('videoSeekForwardEvent'))
      } else if (event.key === ' ') {
        window.dispatchEvent(new CustomEvent('videoTogglePlayEvent'))
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      dispatch(VideoActions.setInitalState())
    }
  }, [dispatch])

  const onPlayingStateChange = useCallback(
    (state: boolean) => {
      dispatch(VideoActions.setPlayingState(state))
    },
    [dispatch]
  )

  const onVideoEnded = useCallback(() => {
    let nextVideoId
    if (video?.group_id) {
      const videoIndex = videosFromGroup?.findIndex(otherVideo => video.id === otherVideo.id)
      nextVideoId = videosFromGroup?.[videoIndex - 1]?.id
    } else {
      nextVideoId = video?.next_video?.id
    }
    if (nextVideoId) {
      const path = generatePath(PATH.VIDEO, { projectId: String(projectId), id: String(nextVideoId) })
      navigate(path)
      dispatch(VideoActions.setPlayingState(true))
    }
  }, [dispatch, navigate, projectId, video?.group_id, video?.id, video?.next_video?.id, videosFromGroup])

  const onRateChanged = useCallback(
    (rate: number) => {
      dispatch(VideoActions.setRate(rate))
    },
    [dispatch]
  )

  const setInited = useCallback(
    (state: boolean) => {
      dispatch(VideoActions.setInited(state))
    },
    [dispatch]
  )

  const progress = useMemo(() => {
    if (projectLoadingProgress === PROGRESS_TYPES.SUCCESS && videoLoadingProgress === PROGRESS_TYPES.SUCCESS) {
      return PROGRESS_TYPES.SUCCESS
    }
    if (projectLoadingProgress === PROGRESS_TYPES.ERROR || videoLoadingProgress === PROGRESS_TYPES.ERROR) {
      return PROGRESS_TYPES.ERROR
    }
    return PROGRESS_TYPES.WORK
  }, [projectLoadingProgress, videoLoadingProgress])

  return (
    <>
      {progress === PROGRESS_TYPES.SUCCESS && (
        <>
          <div className={style.block}>
            <TopBlock />
            <VideoPlayer
              video={video}
              autoplay={videoPlaying}
              onTimeUpdate={onTimeUpdate}
              onPlayingStateChange={onPlayingStateChange}
              setInited={setInited}
              onVideoEnded={onVideoEnded}
              playbackRate={videoPlaybackRate}
              onPlaybackRateChanged={onRateChanged}
            />
            <MiniMap />
            <OtherVideosButton />
          </div>
          {<OtherVideosBlock show={showOtherVideos} />}
        </>
      )}
      {progress === PROGRESS_TYPES.WORK && <Loader className={style.loader} />}
      {progress === PROGRESS_TYPES.ERROR && <div>Ошибка загрузки проекта</div>}
    </>
  )
}

export default Video
