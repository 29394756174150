import { Button } from 'components/Button/Button'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { VideoActions } from 'store/actions/video'
import { RootState } from 'utils/interfaces'
import { ReactComponent as DownArrowSvg } from 'images/icons/arrowDown.svg'
import { ReactComponent as UpArrowSvg } from 'images/icons/arrowUp.svg'

import style from './OtherVideosButton.module.css'

const OtherVideosButton = () => {
  const dispatch = useDispatch()
  const isPlaying = useSelector((state: RootState) => state.video.isPlaying)
  const showOtherVideos = useSelector((state: RootState) => state.video.showOtherVideos)

  const onClick = useCallback(() => {
    dispatch(VideoActions.toggleShowOtherVideos())
    window.dispatchEvent(new Event('videoPausedEvent'))
  }, [dispatch])

  useEffect(() => {
    if (isPlaying) {
      dispatch(VideoActions.setShowOtherVideos(false))
    }
  }, [dispatch, isPlaying])

  return (
    <Button.Outlined className={style.button} onClick={onClick}>
      <span className={style.buttonText}>Другие видео</span>
      {showOtherVideos ? <DownArrowSvg /> : <UpArrowSvg />}
    </Button.Outlined>
  )
}

export default OtherVideosButton
