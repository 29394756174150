import { useState } from 'react'
import { Button } from 'components/Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'utils/interfaces'
import { ReactComponent as DownArrowSvg } from 'images/icons/arrowDown.svg'
import { ReactComponent as UpArrowSvg } from 'images/icons/arrowUp.svg'
// import { ReactComponent as AnnotationSvg } from 'images/icons/annotation.svg'
import { ReactComponent as TaskSvg } from 'images/icons/task.svg'
import dateFnsFormat from 'date-fns/format'
import ru from 'date-fns/locale/ru'
import PicketButton from './PicketButton/PicketButton'
import clsx from 'clsx'
import { VideoActions } from 'store/actions/video'
import logoTwoSrc from 'images/new/logo2.png'
import ButtonBack from './ButtonBack/ButtonBack'
import Tasks from './Tasks/Tasks'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { PATH } from 'utils/constants'

import style from './TopBlock.module.css'

const TopBlock = () => {
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useDispatch()
  const [showTasks, setShowTasks] = useState(false)
  const video = useSelector((state: RootState) => state.video.video)
  const project = useSelector((state: RootState) => {
    return state.project.project
  })

  const onClick = () => {
    if (!showTasks) {
      dispatch(VideoActions.setShowPickets(false))
    }
    setShowTasks(!showTasks)
  }

  const onClickBack = () => {
    const projectId = params.projectId || ''
    navigate(generatePath(PATH.MAIN_PROJECT, { projectId }))
  }

  return (
    <div className={style.topBlock}>
      <div className={style.leftBlock}>
        <img src={logoTwoSrc} alt="logo" className={style.logo} onClick={onClickBack} />
        <div className={style.row}>
          <ButtonBack onClick={onClickBack} />
          <Button.Outlined className={clsx(style.button, style.projectName)} onClick={onClickBack}>
            <div>
              <span className={style.buttonText}>{project?.name}</span>
              {project?.description && <div style={{ fontSize: '13px' }}>{project?.description}</div>}
            </div>
          </Button.Outlined>
        </div>
      </div>
      <div className={style.rightBlock}>
        {video && (
          <>
            <Button.Outlined className={clsx(style.button, style.date)}>
              <span className={style.buttonText}>
                {dateFnsFormat(new Date(video.date || ''), 'dd LLL yyyy', { locale: ru })}
              </span>
            </Button.Outlined>
          </>
        )}
        <PicketButton closeAnnotationsCb={() => setShowTasks(false)} />
        {/* <Button.Outlined className={style.button}>
          <AnnotationSvg />
          <span className={style.buttonText}>Отчеты</span>
          <DownArrowSvg />
        </Button.Outlined> */}
        <Button.Outlined className={clsx(style.button, style.relative)} onClick={onClick}>
          <TaskSvg />
          <span className={style.buttonText}>Задачи</span>
          {showTasks ? <UpArrowSvg /> : <DownArrowSvg />}
        </Button.Outlined>
        <Tasks show={showTasks} />
      </div>
    </div>
  )
}

export default TopBlock
