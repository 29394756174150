import { VideoAnnotation } from 'api/schema'

export const isOverdue = (annotation: VideoAnnotation) => {
  return (
    (!annotation.is_resolved &&
      annotation.scheduled_to_resolve_at &&
      new Date(annotation.scheduled_to_resolve_at) < new Date()) ||
    (annotation.is_resolved &&
      annotation.scheduled_to_resolve_at &&
      annotation.resolved_at &&
      new Date(annotation.resolved_at) > new Date(annotation.scheduled_to_resolve_at))
  )
}
